import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Swal from "sweetalert2";
import { useDispatch } from 'react-redux';
import { colorStartDelete } from '../../../actions/colors';
import { ColorAdminScreen1 } from './ColorAdminScreen1';
import { CreateColor } from './CreateColor';

export const ColorAdminScreen = () => {
  const [showCreate, setShowCreate] = useState(false);
  const dispatch = useDispatch();

  const deleteColor = ({name, id}) => {
  Swal.fire({
      title: `Borrar talla ${name}`,
      text: "¿Estas seguro de borrar esta talla?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3d4c6f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(colorStartDelete(id));
      }
    })
  }

  const openCreateModal = () => {
    setShowCreate(true);
  }

  const cancelCreateColor = () => {
    setShowCreate(false);
  }

  return (
    <div>
      {!showCreate && 
        <>
          <ColorAdminScreen1 deleteColor={deleteColor}/>
          <div className="add-button-div">
            <IconButton color="secondary" aria-label="add an alarm" onClick={openCreateModal}>
              <AddCircleIcon className="add-button"/>
            </IconButton>
          </div>
        </>
      }
      {showCreate && <CreateColor cancel={cancelCreateColor}/>}
    </div>
  );
}
