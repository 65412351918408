import React from 'react';
import Swal from 'sweetalert2';
import { messages } from './footerMessages';

export const FooterScreen = () => {

    const openModal = (index) => {
        Swal.fire({
            title: messages[index].tittle,
            confirmButtonColor: '#3d4c6f',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            html: messages[index].text,
            width: '90%'
          })
    }
    
    const openMaps = () => {
        window.open('https://www.google.com/maps/place/Fundas+de+Sof%C3%A1+y+Cortinas+-+Hogar+Socovos/@38.9973438,-1.8743113,16.79z/data=!4m5!3m4!1s0x0:0x65ece28acbcc946f!8m2!3d38.99728!4d-1.87373?hl=es');
    }

    return (
        <div className="footer">
            <div className="footer-container">
                <div>
                    <h4>INFORMACIÓN</h4>
                    <ul>
                        <li className="footer_a" onClick={() => openModal(0)}>Aviso legal</li>
                        <li className="footer_a" onClick={() => openModal(1)}>Condiciones de compra</li>
                        <li className="footer_a" onClick={() => openModal(2)}>Envíos y devoluciones</li>
                        <li className="footer_a" onClick={() => openModal(3)}>Formas de pago</li>
                    </ul>
                </div>
                <div>
                    <h4>CONTACTA</h4>
                    <ul>
                        <li className="footer_a"><i className="fas fa-phone"></i>967 61 77 66</li>
                        <li className="footer_a"><i className="fas fa-envelope"></i><a href={`mailto:alfonsosocovos@gmail.com`}>alfonsosocovos@gmail.com</a></li>
                        <li className="footer_a" onClick={openMaps}><i className="fas fa-home"></i>C/ Águila nº4, Albacete</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
