import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showAdmin } from '../../actions/auth';
import { AdminLoginScreen } from './AdminLoginScreen';
import { AdminMainScreen } from '../admin/AdminMainScreen';

export const AdminScreen = () => {
    
    const dispatch = useDispatch();
    const { uid, role } = useSelector( state => state.auth);

    useEffect(() => {
        dispatch(showAdmin());
    }, [dispatch]);



    return (
        <div>
            {!uid && <AdminLoginScreen />}
            {!!uid && !!role && <AdminMainScreen />}
            {!!uid && !role && <h1>Usuario no permitido</h1>}
        </div>
    )
}
