import React, { useEffect, useState } from 'react'
import { CartArticle } from './CartArticle';
import Button from '@material-ui/core/Button';
import { Checkout } from './Checkout';
import { useDispatch, useSelector } from 'react-redux';
import { setCarProducts } from '../../actions/ui';
import Typography from '@material-ui/core/Typography';
import { navigationSetCart, navigationSetLogin, navigationSetRegister, navigationSetSpace } from '../../actions/navigation';
import { fetchSinToken } from './../../helpers/fetch';

export const Cart = () => {
    const [products, setProducts] = useState([]);
    const [noStock, setNoStock] = useState(false);
    const [showCheckOut, setShowCheckOut] = useState(false);
    const dispatch = useDispatch();
    const { uid } = useSelector( state => state.auth);

    const pdrs = localStorage.getItem('products');
    useEffect(() => {
        if(pdrs){
            const prs = JSON.parse(localStorage.getItem('products'));

            const products = prs.map(async(pr) => {
                const detail = await getArticleDetail(pr.productId);
                if(!detail.stock) {
                    setNoStock(true);
                }
                return {
                ...pr,
                price: detail.price,
                stock: detail.stock
                }
            });

            Promise.all(products).then(values => {
                setProducts(values);
              });
        }
    }, [pdrs]);

    
    const total = products.reduce( function(a, b){
        return a + b['price']*b['cantidad'];
    }, 0);

    const totalAux = total + 5;

    const cantidadAux = products.reduce( function(a, b){
        return a + b['cantidad'];
    }, 0);

    const cantidad = `(${cantidadAux} productos)`;

    const handleDeleteArticle = (e) => {
        /* let prodc = products.filter(pr => pr.productId !== e); */

        let prodc = products.map(pr => {
            if(pr.productId === e && pr.cantidad > 0) {
                return {
                    ...pr,
                    cantidad: pr.cantidad -1
                }
            } else {
                return pr;
            }
        });
        prodc = prodc.filter(pr => pr.cantidad !== 0);
        setProducts(prodc);

        setNoStock(false);
        prodc.forEach(pr => {
            if(!pr.stock) {
                setNoStock(true);
            }
        })

        localStorage.setItem('products', JSON.stringify(prodc));
        dispatch(setCarProducts(prodc.length));
    }

    const tramitarPedido = () => {
        setShowCheckOut(true);
    }

    const goBack = () => {
        setShowCheckOut(false);
    }

    const goToLogin = () => {
        dispatch(navigationSetCart(false));
        dispatch(navigationSetRegister(false));
        dispatch(navigationSetSpace(false));
        dispatch(navigationSetLogin(true));
    }

    const getArticleDetail = async (id) => {
        const resp = await fetchSinToken(`articles/detail/${id}`, 'GET');
        const body = await resp.json();
        return body.articulo;
    }

    return (
        <div className="cesta">
            {!showCheckOut && 
                <>
                    <Typography variant="h4" gutterBottom>
                        Cesta
                    </Typography>
                    <hr/>
                    {products.length > 0 &&
                        products.map(pr => (
                            <CartArticle key={pr.productId} article={pr} deleteArticle={handleDeleteArticle}/>
                        ))
                    }
                    {products.length === 0 && 
                        <div className="admin-content-wrp">
                            <Typography variant="h4" gutterBottom>
                                Su cesta esta vacia
                            </Typography>
                        </div>
                    }
                    {products.length > 0 && <div className="cart-article-total">
                        <h3 className="cart-price">Gastos de envío: 5€</h3>
                        <h2 className="cart-price">Total: {totalAux}€ {cantidad}</h2>
                    </div>}
                    {products.length > 0 && uid &&
                        <div className="tramitar">
                            <Button color="primary"  variant="contained" onClick={tramitarPedido} disabled={noStock}>
                                Tramitar pedido
                            </Button>
                        </div>
                    }
                    {products.length > 0 && !uid &&
                        <div className="tramitar">
                            <Button color="primary"  variant="contained" onClick={goToLogin} disabled={noStock}>
                                Tramitar pedido
                            </Button>
                        </div>
                    }
                </>
            }            
            {showCheckOut && 
                <Checkout products={products} goBack={goBack}/>
            }
        </div>
    )
}
