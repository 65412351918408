import React from 'react'
import MaterialTable from 'material-table';
import { useSelector } from 'react-redux';

export const SizeAdminScreen1 = (props) => {
    const { sizes } = useSelector( state => state.size);
    
    return (
        <>
            {(sizes.length > 0) &&
                <MaterialTable
                    title="Listado de Tallas"
                    data={sizes}
                    columns={[
                    { title: 'Nombre', field: 'name' }
                    ]}
                    options={{
                        pageSize: 10}}
                    actions={[
                        {
                            icon: 'delete',
                            tooltip: 'Borrar talla',
                            onClick: (event, rowData) => props.deleteSize({name: rowData.name, id:rowData.id})
                        }
                    ]}
                />
            }
        </>
    )
}
