import React from 'react'
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';

export const ArticleAdminScreen1 = (props) => {
    const { articles } = useSelector( state => state.article);

    return (
        <>
            {(articles.length > 0) &&
                <MaterialTable
                    title="Listado de Artículos"
                    data={articles}
                    options={{
                        pageSize: 10}}
                    columns={[
                    { title: 'Nombre', field: 'name' },
                    { title: 'Descripción', field: 'description' },
                    { title: 'Categoría', field: 'idCategoria.name' }
                    ]}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Editar Artículo',
                            onClick: (event, rowData) => props.editArticle(rowData)
                        },
                        {
                            icon: 'delete',
                            tooltip: 'Borrar Artículo',
                            onClick: (event, rowData) => props.deleteArticle({name: rowData.name, id:rowData.id})
                        }
                    ]}
                />
            }
        </>
    )
}
