import React from 'react'
import { Provider } from 'react-redux';
import { store } from './store/store';
import { AppRouter } from './router/AppRouter';
import { createMuiTheme, ThemeProvider  } from '@material-ui/core/styles';

export const ShopApp = () => {
    const theme = createMuiTheme({
        palette: {
            primary: {
              light: '#B2EBF2',
              main: '#3d4c6f',
              dark: '#af6989',
              contrastText: '#FFFFFF'
            },
            secondary: {
                main: '#af6989'
            }
        }
      });

    return (
        <Provider store={ store }>
            <ThemeProvider theme={theme}>
                <AppRouter />
            </ThemeProvider>
        </Provider>
    )
}
