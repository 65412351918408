import React from 'react'
import { Categories } from './Categories'

export const ShopScreen = () => {
    return (
        <div>
            <Categories />
        </div>
    )
}
