import { fetchConToken, fetchSinToken } from '../helpers/fetch';
import { types } from './../types/types';
import { navigationSetRegister } from '../actions/navigation';
import { setLoading } from '../actions/ui';
import Swal from "sweetalert2";

export const showAdmin = () => ({
    type: types.authSetAdmin
});

export const hideAdmin = () => ({
    type: types.authRemoveAdmin
});

export const startLogin = (email, password) => {
    return async(dispatch) => {
        dispatch(setLoading(true));
        const resp = await fetchSinToken('auth', {email, password}, 'POST');
        const body = await resp.json();
        dispatch(setLoading(false));
        if(body.ok) {
            localStorage.setItem('token', body.token);
            localStorage.setItem('token-init-date', new Date().getTime());

            const role = checkRole(body.token);
            dispatch(login({
                uid: body.uid,
                name: body.name,
                role
            }));


        } else {
            Swal.fire('Error', body.msg, 'error');
        }
    }
}

export const logOut = () => {
    
}

export const startRegister = (email, name, password) => {
    return async(dispatch) => {
        dispatch(setLoading(true));
        const resp = await fetchSinToken('auth/new', {email, password, name}, 'POST');
        const body = await resp.json();
        dispatch(setLoading(false));

        if(body.ok) {
            localStorage.setItem('token', body.token);
            localStorage.setItem('token-init-date', new Date().getTime());

            Swal.fire({
                icon: 'success',
                title: 'Operación realizada correctamente',
                showConfirmButton: false,
                timer: 1500
              });
            dispatch(navigationSetRegister(false));
            dispatch(login({
                uid: body.uid,
                name: body.name
            }));
        } else {
            Swal.fire('Error', body.msg, 'error');
        }
    }
}

export const startChecking = () => {
    return async(dispatch) => {
        const resp = await fetchConToken('auth/renew', 'GET');
        const body = await resp.json();

        if(body.ok) {
            localStorage.setItem('token', body.token);
            localStorage.setItem('token-init-date', new Date().getTime());
            const role = checkRole(body.token);

            dispatch(login({
                uid: body.uid,
                name: body.name,
                role
            }));
        } else {
           dispatch(checkingFinish());
        }
    }
}

const checkingFinish = () => ({
    type: types.authCheckingFinish
});

const login = (user) => ({
    type: types.authLogin,
    payload: user
});

export const startLogout = () => {
    return ( dispatch ) => {
        localStorage.clear();
        dispatch(logout());
    }
}

const checkRole = (token) => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const tokenJ = JSON.parse(jsonPayload);
    return ( tokenJ.role && tokenJ.role === process.env.REACT_APP_ROLE) ? true : false;    
};

const logout = () => ({type: types.authLogout});