import { fetchConToken, fetchFormConToken, fetchSinToken } from "../helpers/fetch";
import Swal from "sweetalert2";
import { types } from './../types/types';
import { doneTrue } from "./ui";

export const getCategories = () => {
    return async(dispatch) => {
        const resp = await fetchSinToken('categories', 'GET');
        const body = await resp.json();
        if(body.ok) {

            dispatch(setCategories(
                body.categorias
            ));
        } else {
            Swal.fire('Error', body.msg, 'error');
        }
    }
}

const setCategories = (data) => ({
    type: types.categoriesSet,
    payload: data
});

export const categoryStartAddNew = (event) => {
    return async(dispatch) => {
        const {form, file} = event;
        try {
            const resp = await fetchConToken('categories', form, 'POST');
            const body = await resp.json();

            if(body.ok) {
                dispatch(categoryFinishNew(body.categoria));

                if(file) {
                    dispatch(categoryUploadFile({id: body.categoria.id , file}));
                } else {
                    dispatch(doneTrue());
                }                
            } else{
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error, 'error');
        }
    }
}

const categoryFinishNew = (category) => ({
    type: types.categoryFinishNew,
    payload: category
});

export const categoryStartDelete = (id) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`categories/${id}`, {},'DELETE');
            const body = await resp.json();

            if(body.ok) {
                dispatch(categoryDeleted(id));
                dispatch(doneTrue());
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error, 'error');
        }
    }
}

const categoryDeleted = (id) => ({
    type: types.categoryDeleted,
    payload: id
});

export const categoryStartEdit = (event) => {

    const {form, file} = event;

    return async(dispatch) => {
        try {
            const resp = await fetchConToken(`categories/${form.id}`, form, 'PUT');
            const body = await resp.json();

            if(body.ok) {
                dispatch(categoryUpdated(form));
                if(file) {
                    dispatch(categoryUploadFile({id: form.id , file}));
                } else {
                    dispatch(doneTrue());
                }         
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error.toString(), 'error');
        }
    }    
}

const categoryUpdated = (event) => ({
    type: types.categoryUpdated,
    payload: event
});


const categoryUploadFile = ({id, file}) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFormConToken(`upload/categories/${id}`, {archivo: file},'PUT');
            const body = await resp.json();

            if(body.ok) {
                dispatch(categoryUpdated(body.categoria));
                dispatch(doneTrue());
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error, 'error');
        }
    }
};