import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import { getClientOrderById } from '../../actions/orders';
import Button from '@material-ui/core/Button';

export const Orders = (props) => {
    let { orders, orderDetail } = useSelector( state => state.client);
    const [showTable, setShowTable] = useState(true);
    const [showOrdersDetail, setshowOrdersDetail] = useState([]);
    const [ titleTableOrder , setTitleTableOrder] = useState('');
    const [ titleTableOrder1 , setTitleTableOrder1] = useState('');
    const dispatch = useDispatch();

    orders = orders.map(or => ({
        ...or,
        date: new Date(or.date).toLocaleString(),
        total: or.total + ' €'
    }));

    const viewOrder = (data) => {
        setTitleTableOrder(`Detalle del pedido ${data.id} / Precio total: ${data.total}`);
        setTitleTableOrder1(`Enviado a: ${data.name}, ${data.adress}`);
        dispatch(getClientOrderById(data.id));
        setShowTable(false);
    }

    const viewTable = () => {
        setShowTable(true);
    }

    useEffect(() => {
        if(!!orderDetail) {
            setshowOrdersDetail(
                orderDetail.map(or => ({
                    ...or,
                    total: or.total + ' €',
                    price: or.price + ' €'
                }))
            )
        }
    }, [orderDetail])

    return (
        <div className="client-orders-table">
            {(orders.length > 0) && showTable &&
                <MaterialTable
                    title="Mis pedidos"
                    data={orders}
                    options={{
                        pageSize: 10}}
                    columns={[
                    { title: 'Estado', field: 'status'},
                    { title: 'Id', field: 'id'},
                    { title: 'Nombre', field: 'name' },
                    { title: 'Dirección', field: 'adress' },
                    { title: 'Fecha', field: 'date' },
                    { title: 'Total', field: 'total' },
                    { title: 'Observaciones', field: 'observations' }
                    ]}
                    actions={[
                        {
                            icon: 'visibility',
                            tooltip: 'Ver Pedido',
                            onClick: (event, rowData) => viewOrder(rowData)
                        }
                    ]}
                />
            }
            {
                !showTable && showOrdersDetail && 
                <>
                <div className="order-button">
                <Button variant="contained" color="primary" onClick={viewTable}>
                    Ver pedidos
               </Button>
                </div>
                    <h3>{titleTableOrder}</h3>
                    <h4>{titleTableOrder1}</h4>
                    <MaterialTable
                        title="Articulos"
                        data={showOrdersDetail}
                        options={{pageSize: 10}}
                        columns={[
                            { title: 'Nombre', field: 'name'},
                            { title: 'Cantidad', field: 'amount'},
                            { title: 'Color', field: 'color' },
                            { title: 'Talla', field: 'size' },
                            { title: 'Precio', field: 'price' },
                            { title: 'Total', field: 'total' }
                        ]}
                    />
                </>
            }
        </div>
    )
}
