import { types } from "../types/types";

const initialState = {
    colors: []
}

export const colorReducer = ( state = initialState, action ) => {
    switch(action.type) {
       case types.colorsSet:
           return {
               ...state,
               colors: action.payload
           }
        case types.colorFinishNew:
            return {
                ...state,
                colors: [...state.colors, action.payload]
            }
        case types.colorDeleted:
            return {
                ...state,
                colors: state.colors.filter(c => (
                        c.id !== action.payload) 
                )
            }
        case types.colorUpdated:
            return {
                ...state,
                colors: state.colors.map(e => (
                     e.id === action.payload.id) ? action.payload : e
                )
            }
        default:
            return state;
    }
}