import { types } from "../types/types";

const initialState = {
    modalConfirmOpen: false,
    showLoading: false,
    cartProducts: 0
}

export const uiReducer = ( state = initialState, action ) => {
    switch(action.type) {
        case types.uiDoneTrue:
            return {
                ...state,
                modalConfirmOpen: true
            }
        case types.uiDoneFalse:
            return {
                ...state,
                modalConfirmOpen: false
            }
        case types.uiSetLoading:
            return {
                ...state,
                showLoading: action.payload
            }
        case types.uiSetCartProducts: {
            return {
                ...state,
                cartProducts: action.payload
            }
        }
        default:
            return state;
    }
}