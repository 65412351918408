import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getArticlesByCategory } from '../../actions/articles';
import { Article } from './Article';

export const Articles = ({categorie}) => {
    const dispatch = useDispatch();

    useEffect(() => {     
        if(!!categorie)   {
            dispatch(getArticlesByCategory(categorie.id));
        }
        
    }, [dispatch, categorie])

    const {articlesByCategory} = useSelector(state => state.article)

    return (
        <div className="articles">
            {
                articlesByCategory.map((ar) => (
                    <Article article={ar} key={ar._id}/>
                ))
            }
        </div>
    )
}
