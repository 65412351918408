import { types } from "../types/types";

const initialState = {
    orders: [],
    data: null,
    orderDetail: null
}

export const clientReducer = ( state = initialState, action ) => {
    switch(action.type) {
       case types.clientOrdersSet:
           return {
               ...state,
               orders: action.payload
           }
        case types.clientOrderSetDetail:
            return {
                ...state,
                orderDetail: action.payload
            }
        default:
            return state;
    }
}