import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';

import {
    useLocation,
    useHistory
} from "react-router-dom";
import { checkOutValidate } from './../../actions/checkout';

export const CartResponse = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [operationOk, setOperationOk] = useState(true);

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;
        if (path === "/cancel") {
            setOperationOk(false);
        } else {
            const params = new URLSearchParams(location.search)
            if (params.get('id')) {
                dispatch(checkOutValidate({
                    id: params.get('id')
                }));
                history.push('/accept');
            }
        }
    }, [location, dispatch, history]);

    const goHome = () => {
        history.push('/');
    }

    return (
        <div className="cart-response">
            {
                operationOk && <Alert severity="success" onClose={goHome}>Pedido realizado con exito</Alert>
            }
            {
                !operationOk && <Alert severity="error" onClose={goHome}>Su operación ha sido cancelada</Alert>
            }
        </div>
    )
}
