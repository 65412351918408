import React from 'react'
import { useSelector } from 'react-redux';

export const TopHeader = () => {
    const { name } = useSelector( state => state.auth);
    return (
        <div className="top-header">
            <div className="top-header-message">Cortinas y hogar Socovos - C/Aguila nº 4, Albacete</div> 
            <div className="top-header-social-icons">
                <ul>
                    <li className="top-header-social-icon"><a target="_blank" href="https://www.facebook.com/hogarsocovos/"><i className="fab fa-facebook"></i></a></li>
                    <li className="top-header-social-icon"><a target="_blank" href="https://www.google.com/maps/place/Fundas+de+Sof%C3%A1+y+Cortinas+-+Hogar+Socovos/@38.9973438,-1.8743113,16.79z/data=!4m5!3m4!1s0x0:0x65ece28acbcc946f!8m2!3d38.99728!4d-1.87373?hl=es"><i className="fas fa-map-marker-alt"></i></a></li>
                </ul>
                {!!name && <h3>Hola {name}</h3>}
            </div>
        </div>                                             
    )
}
