import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { checkOutStartCheck } from './../../actions/checkout';
import Typography from '@material-ui/core/Typography';

const initEvent = {
    name: '',
    surname: '',
    address: '',
    postalCode: '',
    province: '',
    city: '',
    phone: '',
    observations: '',
};

export const Checkout = ({products, goBack}) => {
    const [isFormValid, setIsFormValid] = useState(false);
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState(initEvent);
    const {name, address, postalCode, province, city, phone, observations} = formValues;
    const provinces = ['Alava','Albacete','Alicante','Almería','Asturias','Avila','Badajoz','Barcelona','Burgos','Cáceres',
    'Cádiz','Cantabria','Castellón','Ciudad Real','Córdoba','La Coruña','Cuenca','Gerona','Granada','Guadalajara',
    'Guipúzcoa','Huelva','Huesca','Islas Baleares','Jaén','León','Lérida','Lugo','Madrid','Málaga','Murcia','Navarra',
    'Orense','Palencia','Las Palmas','Pontevedra','La Rioja','Salamanca','Segovia','Sevilla','Soria','Tarragona',
    'Santa Cruz de Tenerife','Teruel','Toledo','Valencia','Valladolid','Vizcaya','Zamora','Zaragoza'];

    useEffect(() => {
        if(name.trim() !== '' && address.trim() !== '' && postalCode.trim() !== '' && province.toString().trim() !== '' && city.toString().trim() !== '' && phone.toString().trim() !== '') {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [name, address, postalCode, province, city, phone]);

    const total = products.reduce( function(a, b){
        return a + (b['price']*b.cantidad);
    }, 0);

    const totalAux = total + 5;

    const handleInputChange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.name]:target.value
        })
    }

    const handleSelectChange = (event) => {
        handleInputChange({target: {name: 'province', value: event.target.value}});
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const formMounted = mountForm({formValues, products});
        dispatch(checkOutStartCheck({form: formMounted}));
    }

    const mountForm = ({formValues, products}) => {
        return {
            adress: `${formValues.address} ${formValues.postalCode} ${formValues.province} ${formValues.city}`,
            name: formValues.name,
            phone: formValues.phone,
            observations: formValues.observations,
            pedidoDetail: products.map(pr => ({
                articuloDetalle: pr.productId,
                amount: pr.cantidad
            }))
        }
    }

    return (
        <div>
            <Container maxWidth="lg">
                <Typography variant="h4" gutterBottom className="screen">
                    Tramitar pedido / {totalAux} €
                </Typography>

                <Typography variant="h5" gutterBottom className="mobile">
                    Tramitar pedido / {totalAux} €
                </Typography>


                
                <input type="hidden" id="token" ></input>
                <input type="hidden" id="errorCode" ></input>
                
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className='create-category-container'>
                            <form className='create-category-form' noValidate autoComplete="off" onSubmit={handleSubmit}>
                                <TextField id="standard-basic" label="*Nombre y apellidos" name="name" value={name} onChange={handleInputChange}/>
                                <TextField id="standard-basic" label="*Dirección Completa" name="address" value={address} onChange={handleInputChange}/>
                                <TextField id="standard-basic" label="*Codigo Postal" name="postalCode" value={postalCode} type="number" onChange={handleInputChange}/>
                                <TextField id="standard-basic" label="*Ciudad" name="city" value={city} onChange={handleInputChange}/>
                                <FormControl  aria-label="position">
                                    <InputLabel id="demo-simple-select-label">*Provincia</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={province}
                                        onChange={handleSelectChange}
                                    >
                                        <MenuItem value="">
                                            <em>----</em>
                                        </MenuItem>
                                        {provinces.map(prov => <MenuItem key={prov} value={prov}>{prov}</MenuItem>)}
                                    </Select>
                                </FormControl >
                                <TextField id="standard-basic" label="*Teléfono" name="phone" value={phone} onChange={handleInputChange}/>
                                <TextField
                                    id="standard-multiline-static"
                                    label="Observaciones"
                                    multiline
                                    rows={4}
                                    name="observations"
                                    value={observations} onChange={handleInputChange}
                                    />
                                <div className='create-category-form-buttons'>
                                    <Button color="primary"  variant="contained" onClick={goBack}>
                                        Cancelar
                                    </Button>
                                    <Button variant="contained" color="primary" disabled={!isFormValid} type="submit">
                                            Finalizar pedido
                                    </Button>
                                </div>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            
        </div>
    )
}
