import { types } from "../types/types";

const initialState = {
    textRoute: '',
    subTextRoute: '',
    categorieRoute: '',
    subCategorieRoute: '',
    viewCart: false,
    viewLogin: false,
    viewRegister: false,
    viewSpace: false
}

export const navigationReducer = ( state = initialState, action ) => {
    switch(action.type) {
       case types.navigationSetText:
           return {
               ...state,
               textRoute: action.payload
           }
        case types.navigationDeleteText:
            return {
                ...state,
                textRoute: ''
            }
        case types.navigationSetSubText:
            return {
                ...state,
                subTextRoute: action.payload
            }
        case types.navigationDeleteSubText:
            return {
                ...state,
                subTextRoute: ''
            }            
        case types.navigationSetCategorie:
            return {
                ...state,
                categorieRoute: action.payload
            }
        case types.navigationSetSubCategorie:
            return {
                ...state,
                subCategorieRoute: action.payload
            }            
        case types.navigationSetCart:
            return {
                ...state,
                viewCart: action.payload
            }
        case types.navigationSetLogin:
            return{
                ...state,
                viewLogin: action.payload
            }
        case types.navigationSetRegister:
            return{
                ...state,
                viewRegister: action.payload
            }
        case types.navigationSetSpace:
            return{
                ...state,
                viewSpace: action.payload
            }
        default:
            return state;
    }
}