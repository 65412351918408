import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { navigationReducer } from './navigationReducer';
import { categoryReducer } from './categoriesReducer';
import { uiReducer } from './uiReducer';
import { sizeReducer } from './sizesReducer';
import { colorReducer } from './colorsReducer';
import { articleReducer } from './articleReducer';
import { clientReducer } from './clientReducer';
import { ordersReducer } from './ordersReducer';

export const rootReducer = combineReducers ({
    ui: uiReducer,
    navigation: navigationReducer,
    auth: authReducer,
    category: categoryReducer,
    size: sizeReducer,
    color: colorReducer,
    article: articleReducer,
    client: clientReducer,
    order: ordersReducer
});