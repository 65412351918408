import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {AdminLoginScreen} from '../auth/AdminLoginScreen';
import { navigationSetLogin } from '../../actions/navigation';
import Typography from '@material-ui/core/Typography';

export const Login = () => {
    const{ uid } = useSelector( state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!!uid) {
            dispatch(navigationSetLogin(false));
        }        
    }, [uid, dispatch])

    return (
        <div>

            <div className="content-title">
                <Typography variant="h4" gutterBottom>
                    Iniciar sesión
                </Typography>
            </div>
            <AdminLoginScreen wrap={true}/>
        </div>
    )
}
