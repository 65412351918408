export const messages = [
    {
        tittle: 'Aviso legal',
        text: `<div style='text-align: left'> El sitio web www.hogarsocovos.es (en adelante, el “Sitio Web”) es propiedad de
        ALFONSO GARCÍA MARTÍNEZ –CORTINAS SOCOVOS-., (en delante, “CORTINAS
        SOCOVOS”), con domicilio social en C/ del Águila, 4, 02.006, Albacete.<br>
        “CORTINAS SOCOVOS” le da la bienvenida y le invita a leer atentamente las Condiciones
        Generales de Uso de este Sito Web (en adelante, las “Condiciones Generales de Uso”) que
        describen los términos y condiciones que serán aplicables a su navegación por el mismo,
        de conformidad con lo establecido en la normativa española de aplicación. Dado que
        “CORTINAS SOCOVOS” podría modificar en el futuro estas Condiciones de Uso, le
        recomendamos que las visite periódicamente para estar debidamente informado de los
        cambios realizados.<br>
        Con el ánimo de que el uso del Sitio Web se ajuste a criterios de transparencia, claridad y
        sencillez, “CORTINAS SOCOVOS” informa al Usuario que cualquier sugerencia, duda o
        consulta sobre las Condiciones Generales de Uso será recibida y atendida contactando con
        “CORTINAS SOCOVOS” de la dirección de correo electrónico: alfonsosocovos@gmail.com.<br>
        <h2>1. Objeto</h2>
        “CORTINAS SOCOVOS” suministra el contenido y los servicios que están disponibles en el
        Sitio Web, con sujeción a las presentes Condiciones Generales de Uso así como a la política
        sobre tratamiento de datos personales (en adelante, la “Política de Protección de Datos”).
        El acceso a este Sitio Web o su utilización en cualquier forma le otorga la calificación de
        “Usuario” e implica la aceptación sin reservas de todas y cada una de las presentes
        Condiciones Generales de Uso, reservándose “CORTINAS SOCOVOS” el derecho a
        modificarlos en cualquier momento. En consecuencia, será responsabilidad de todo
        Usuario, la atenta lectura de las Condiciones Generales de Uso vigente en cada una de las
        ocasiones en que acceda a este Sitio Web, por lo que si éste no está de acuerdo con
        cualquiera de los mismos aquí dispuestos, deberá abstenerse respecto al uso del presente
        Sitio Web.
        Asimismo, queda advertido de que, en ocasiones, se podrán establecer condiciones
        particulares para la utilización en el Sitio Web de contenidos y/o servicios específicos, la
        utilización de dichos contenidos o servicios implicará la aceptación de las condiciones
        particulares en ellos especificadas.<br>
        <h2>2. Servicios</h2>
        A través del Sitio Web, www.hogarsocovos.es , “CORTINAS SOCOVOS”, ofrece a los
        Usuarios la posibilidad de acceder a información de todos nuestros productos y servicios.
        (en adelante los “Servicios”).<br>
        <h2>3. Privacidad y Tratamiento de Datos</h2>
        Cuando para el acceso a determinados contenidos o servicios sea necesario facilitar datos
        de carácter personal, los Usuarios garantizarán su veracidad, exactitud, autenticidad y
        vigencia. “CORTINAS SOCOVOS” dará a dichos datos el tratamiento automatizado que
        corresponda en función de su naturaleza o finalidad, en los términos indicados en la
        sección de Política de Protección de Datos -cláusula LOPD].<br>
        <h2>4. Propiedad Industrial e Intelectual</h2>
        El Usuario reconoce y acepta que todos los contenidos que se muestran en el Sitio Web y
        en especial, diseños, textos, imágenes, logos, iconos, botones, software, nombres
        comerciales, marcas, o cualesquiera otros signos susceptibles de utilización industrial y/o
        comercial están sujetos a derechos de Propiedad Intelectual y todas las marcas, nombres
        comerciales o signos distintivos, todos los derechos de propiedad industrial e intelectual,
        sobre los contenidos y/o cualesquiera otros elementos insertados en el página, que son
        propiedad exclusiva de “CORTINAS SOCOVOS” y/o de terceros, quienes tienen el derecho
        exclusivo de utilizarlos en el tráfico económico. Por todo ello el Usuario se compromete a
        no reproducir, copiar, distribuir, poner a disposición o de cualquier otra forma comunicar
        públicamente, transformar o modificar tales contenidos manteniendo indemne a
        “CORTINAS SOCOVOS” de cualquier reclamación que se derive del incumplimiento de tales
        obligaciones. En ningún caso el acceso al Sitio Web implica ningún tipo de renuncia,
        transmisión, licencia o cesión total ni parcial de dichos derechos, salvo que se establezca
        expresamente lo contrario. Las presentes Condiciones Generales de Uso del Sitio Web no
        confieren a los Usuarios ningún otro derecho de utilización, alteración, explotación,
        reproducción, distribución o comunicación pública del Sitio Web y/o de sus Contenidos
        distintos de los aquí expresamente previstos. Cualquier otro uso o explotación de
        cualesquiera derechos estará sujeto a la previa y expresa autorización específicamente
        otorgada a tal efecto por “CORTINAS SOCOVOS” o el tercero titular de los derechos
        afectados.<br>
        Los contenidos, textos, fotografías, diseños, logotipos, imágenes, programas de ordenador,
        códigos fuente y, en general, cualquier creación intelectual existente en este sitio, así como
        el propio sitio en su conjunto, como obra artística multimedia, están protegidos como
        derechos de autor por la legislación en materia de propiedad intelectual. “CORTINAS
        SOCOVOS” es titular de los elementos que integran el diseño gráfico del Sitio Web, lo
        menús, botones de navegación, el código HTML, los textos, imágenes, texturas, gráficos y
        cualquier otro contenido del Sitio Web o, en cualquier caso dispone de la correspondiente
        autorización para la utilización de dichos elementos. El contenido dispuesto en el Sitio
        Web no podrá ser reproducido ni en todo ni en parte, ni transmitido, ni registrado por
        ningún sistema de recuperación de información, en ninguna forma ni en ningún medio, a
        menos que se cuente con la autorización previa, por escrito, de la citada Entidad.
        Asimismo queda prohibido suprimir, eludir y/o manipular el "copyright" así como los
        dispositivos técnicos de protección, o cualesquiera mecanismos de información que
        pudieren contener los contenidos. El Usuario de este Sitio Web se compromete a respetar
        los derechos enunciados y a evitar cualquier actuación que pudiera perjudicarlos,
        reservándose en todo caso “CORTINAS SOCOVOS” el ejercicio de cuantos medios o
        acciones legales le correspondan en defensa de sus legítimos derechos de propiedad
        intelectual e industrial.<br>
        <h2>5. Obligaciones y Responsabilidades del Usuario del Sitio Web</h2>
        El Usuario se compromete a:<br>
        a) Hacer un uso adecuado y lícito del Sitio Web así como de los contenidos y servicios,
        de conformidad con: (i) la legislación aplicable en cada momento; (ii) las
        Condiciones Generales de Uso del Sitio Web; (iii) la moral y buenas costumbres
        generalmente aceptadas y (iv) el orden público.<br>
        b) Proveerse de todos los medios y requerimientos técnicos que se precisen para
        acceder al Sitio Web.<br>
        c) Facilitar información veraz al cumplimentar con sus datos de carácter personal los
        formularios contenidos en el Sitio Web (si los hubiese) y a mantenerlos
        actualizados en todo momento de forma que responda, en cada momento, a la
        situación real del Usuario. El Usuario será el único responsable de las
        manifestaciones falsas o inexactas que realice y de los perjuicios que cause a
        “CORTINAS SOCOVOS” o a terceros por la información que facilite.<br>
        No obstante lo establecido en el apartado anterior el Usuario deberá asimismo abstenerse
        de:<br>
        a) Hacer un uso no autorizado o fraudulento del Sitio Web y/o de los contenidos con fines
        o efectos ilícitos, prohibidos en las presentes Condiciones Generales de Uso, lesivos de los
        derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar,
        sobrecargar, deteriorar o impedir la normal utilización de los servicios o los documentos,
        archivos y toda clase de contenidos almacenados en cualquier equipo informático.<br>
        b) Acceder o intentar acceder a recursos o áreas restringidas del Sitio Web, sin cumplir las
        condiciones exigidas para dicho acceso.<br>
        c) Provocar daños en los sistemas físicos o lógicos del Sitio Web, de sus proveedores o de
        terceros.<br>
        d) Introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o
        lógicos que sean susceptibles de provocar daños en los sistemas físicos o lógicos de
        “CORTINAS SOCOVOS”, de sus proveedores o de terceros.<br>
        e) Intentar acceder, utilizar y/o manipular los datos de “CORTINAS SOCOVOS”, terceros
        proveedores y otros Usuarios.<br>
        f) Reproducir o copiar, distribuir, permitir el acceso del público a través de cualquier
        modalidad de comunicación pública, transformar o modificar los contenidos, a menos que
        se cuente con la autorización del titular de los correspondientes derechos o ello resulte
        legalmente permitido.<br>
        g) Suprimir, ocultar o manipular las notas sobre derechos de propiedad intelectual o
        industrial y demás datos identificativos de los derechos de “CORTINAS SOCOVOS” o de
        terceros incorporados a los contenidos, así como los dispositivos técnicos de protección o
        cualesquiera mecanismos de información que puedan insertarse en los contenidos.<br>
        h) Obtener e intentar obtener los contenidos empleando para ello medios o
        procedimientos distintos de los que, según los casos, se hayan puesto a su disposición a
        este efecto o se hayan indicado expresamente en las páginas web donde se encuentren los
        contenidos o, en general, de los que se empleen habitualmente en Internet por no entrañar
        un riesgo de daño o inutilización del sitio Web y/o de los contenidos.<br>
        i) En particular, y a título meramente indicativo y no exhaustivo, el Usuario se
        compromete a no transmitir, difundir o poner a disposición de terceros informaciones,
        datos, contenidos, mensajes, gráficos, dibujos, archivos de sonido y/o imagen, fotografías,
        grabaciones, software y, en general, cualquier clase de material que:<br>
        (i) De cualquier forma sea contrario, menosprecie o atente contra los derechos
        fundamentales y las libertades públicas reconocidas constitucionalmente, en los
        Tratados Internacionales y en el resto de la legislación vigente.
        (ii) Induzca, incite o promueva actuaciones delictivas, denigratorias, difamatorias,
        violentas o, en general, contrarias a la ley, a la moral, a las buenas costumbres
        generalmente aceptadas o al orden público.<br>
        (iii) Induzca, incite o promueva actuaciones, actitudes o pensamientos
        discriminatorios por razón de sexo, raza, religión, creencias, edad o condición.<br>
        (iv) Incorpore, ponga a disposición o permita acceder a productos, elementos,
        mensajes y/o servicios delictivos, violentos, ofensivos, nocivos, degradantes o, en
        general, contrarios a la ley, a la moral y a las buenas costumbres generalmente
        aceptadas o al orden público.<br>
        (v) Induzca o pueda inducir a un estado inaceptable de ansiedad o temor.<br>
        (vi) Induzca o incite a involucrarse en prácticas peligrosas, de riesgo o nocivas para
        la salud y el equilibrio psíquico.<br>
        (vii) Se encuentra protegido por la legislación en materia de protección intelectual
        o industrial perteneciente a “CORTINAS SOCOVOS” o a terceros sin que haya sido
        autorizado el uso que se pretenda realizar.<br>
        (viii) Sea contrario al honor, a la intimidad personal y familiar o a la propia imagen
        de las personas.<br>
        (ix) Constituya cualquier tipo de publicidad.<br>
        (x) Incluya cualquier tipo de virus o programa que impida el normal
        funcionamiento del Sitio Web.<br>
        Si para acceder a algunos de los servicios y/o contenidos del Sitio Web, se le
        proporcionara una contraseña, se obliga a usarla de manera diligente,
        manteniéndola en todo momento en secreto. En consecuencia, será responsable de
        su adecuada custodia y confidencialidad, comprometiéndose a no cederla a
        terceros, de manera temporal o permanente, ni a permitir el acceso a los
        mencionados servicios y/o contenidos por parte de personas ajenas. Igualmente,
        se obliga a notificar a “CORTINAS SOCOVOS” cualquier hecho que pueda suponer
        un uso indebido de su contraseña, como, a título enunciativo, su robo, extravío o el
        acceso no autorizado, con el fin de proceder a su inmediata cancelación. En
        consecuencia, mientras no efectúe la notificación anterior, “CORTINAS SOCOVOS”
        quedará eximida de cualquier responsabilidad que pudiera derivarse del uso
        indebido de su contraseña, siendo de su responsabilidad cualquier utilización
        ilícita de los contenidos y/o servicios del Sitio Web por cualquier tercero ilegítimo.
        Si de manera negligente o dolosa incumpliera cualquiera de las obligaciones establecidas
        en las presentes Condiciones Generales de Uso, responderá por todos los daños y
        perjuicios que de dicho incumplimiento pudieran derivarse para “CORTINAS SOCOVOS”.<br>
        <h2>6. Responsabilidades</h2>
        “CORTINAS SOCOVOS” no garantiza el acceso continuado, ni la correcta visualización,
        descarga o utilidad de los elementos e informaciones contenidas en la página
        www.hogarsocovos.com , que pueden verse impedidos, dificultados o interrumpidos por
        factores o circunstancias que están fuera de su control.
        “CORTINAS SOCOVOS” no se hace responsable de las decisiones que pudieran adoptarse
        como consecuencia del acceso a los contenidos o informaciones ofrecidas.
        “CORTINAS SOCOVOS” podrá interrumpir el servicio o resolver de modo inmediato la
        relación con el Usuario si detecta que un uso de su Sitio Web o de cualquiera de los
        servicios ofertados en el mismo son contrarios a las presentes Condiciones Generales de
        Uso. “CORTINAS SOCOVOS” no se hace responsable por daños, perjuicios, pérdidas,
        reclamaciones o gastos derivados del uso del Sitio Web. Únicamente será responsable de
        eliminar, lo antes posible, los contenidos que puedan generar tales perjuicios, siempre que
        así se notifique. En especial no será responsable de los perjuicios que se pudieran derivar,
        entre otros, de:<br>
        (i) interferencias, interrupciones, fallos, omisiones, averías telefónicas, retrasos,
        bloqueos o desconexiones en el funcionamiento del sistema electrónico, motivadas
        por deficiencias, sobrecargas y errores en las líneas y redes de telecomunicaciones,
        o por cualquier otra causa ajena al control de “CORTINAS SOCOVOS”.<br>
        (ii) intromisiones ilegítimas mediante el uso de programas malignos de cualquier
        tipo y a través de cualquier medio de comunicación, tales como virus informáticos
        o cualesquiera otros.<br>
        (iii) abuso indebido o inadecuado del Sitio Web.<br>
        (iv) errores de seguridad o navegación producidos por un mal funcionamiento del
        navegador o por el uso de versiones no actualizadas del mismo. Los
        administradores de “CORTINAS SOCOVOS” se reservan el derecho de retirar, total
        o parcialmente, cualquier contenido o información presente en el Sitio Web.
        “CORTINAS SOCOVOS” excluye cualquier responsabilidad por los daños y perjuicios de
        toda naturaleza que pudieran deberse a la mala utilización de los servicios de libre
        disposición y uso por parte de los Usuarios de Sitio Web. Asimismo, “CORTINAS SOCOVOS”
        queda exonerada de cualquier responsabilidad por el contenido e informaciones que
        puedan ser recibidas como consecuencia de los formularios de recogida de datos, estando
        los mismos únicamente para la prestación de los servicios de consultas y dudas. Por otro
        lado, en caso de causar daños y perjuicios por un uso ilícito o incorrecto de dichos
        servicios, podrá ser el Usuario reclamado por “CORTINAS SOCOVOS” de los daños o
        perjuicios causados.<br>
        Usted defenderá, indemnizará y mantendrá a “CORTINAS SOCOVOS” indemne frente a
        cualesquiera daños y perjuicios que se deriven de reclamaciones, acciones o demandas de
        terceros como consecuencia de su acceso o uso del Sitio Web. Asimismo, usted se obliga a
        indemnizar a “CORTINAS SOCOVOS” frente a cualesquiera daños y perjuicios, que se
        deriven del uso por su parte de “robots”, “spiders”, “crawlers” o herramientas similares
        empleadas con el fin de recabar o extraer datos o de cualquier otra actuación por su parte
        que imponga una carga irrazonable sobre el funcionamiento del Sitio Web.<br>
        <h2>7. Hipervínculos</h2>
        El Usuario se obliga a no reproducir de ningún modo, ni siquiera mediante un hiperenlace
        o hipervínculo, el Sitio Web de “CORTINAS SOCOVOS”, así como ninguno de sus
        contenidos, salvo autorización expresa y por escrito de “CORTINAS SOCOVOS”.
        El Sitio Web de “CORTINAS SOCOVOS” incluye enlaces a otras sitios web gestionados por
        terceros, con objeto de facilitar el acceso del Usuario a la información de empresas
        colaboradoras y/o patrocinadoras. Conforme con ello, “CORTINAS SOCOVOS” no se
        responsabiliza del contenido de dichos sitios web, ni se sitúa en una posición de garante
        ni/o de parte ofertante de los servicios y/o información que se puedan ofrecer a terceros a
        través de los enlaces de terceros.<br>
        Se concede al Usuario un derecho limitado, revocable y no exclusivo a crear enlaces a la
        página principal del Sitio Web exclusivamente para uso privado y no comercial. Los sitios
        web que incluyan enlace a nuestro Sitio Web (i) no podrán dar a entender que “CORTINAS
        SOCOVOS” recomienda ese sitio web o sus servicios o productos; (ii) no podrán falsear su
        relación con “CORTINAS SOCOVOS” ni afirmar que “CORTINAS SOCOVOS” ha autorizado
        tal enlace, ni incluir marcas, denominaciones, nombres comerciales, logotipos u otros
        signos distintivos de “CORTINAS SOCOVOS”; (iii) no podrán incluir contenidos que puedan
        considerarse de mal gusto, obscenos, ofensivos, controvertidos, que inciten a la violencia o
        la discriminación por razón de sexo, raza o religión, contrarios al orden público o ilícitos;
        (iv) no podrán enlazar a ninguna página del Sitio Web distinta de la página principal; (v)
        deberá enlazar con la propia dirección del Sitio Web, sin permitir que el sitio web que
        realice el enlace reproduzca el Sitio Web como parte de su web o dentro de uno de sus
        “frames” o crear un “browser” sobre cualquiera de las páginas del Sitio Web. “CORTINAS
        SOCOVOS” podrá solicitar, en cualquier momento, que elimine cualquier enlace al Sitio
        Web, después de lo cual deberá proceder de inmediato a su eliminación. “CORTINAS
        SOCOVOS” no puede controlar la información, contenidos, productos o servicios facilitados
        por otros sitios web que tengan establecidos enlaces con destino al Sitio Web.
        En consecuencia, “CORTINAS SOCOVOS” no asume ningún tipo de responsabilidad por
        cualquier aspecto relativo a tales sitios web.<br>
        <h2>8. Protección de datos</h2>
        Para utilizar algunos de los Servicios, los Usuarios deben proporcionar previamente
        ciertos datos de carácter personal. Para ello, “CORTINAS SOCOVOS” tratará
        automatizadamente los Datos Personales en cumplimiento con la Ley 15/1999 de 13 de
        diciembre de Protección de Datos de Carácter Personal y el RD de desarrollo 1720/2007.
        Para ello, el Usuario puede acceder a la política seguida en el tratamiento de los datos
        personales así como el establecimiento de las finalidades previamente establecidas, a lo
        dispuesto en las condiciones definidas en la Política de Protección de Datos [LINK] que
        presenta Sitio Web.<br>
        <h2>9. Cookies</h2>
        “CORTINAS SOCOVOS” se reserva el derecho de utilizar la tecnología “cookie” en el Sitio
        Web, a fin de reconocerlo como Usuario frecuente y personalizar el uso que realice del
        Sitio Web mediante la preselección de su idioma, o contenidos más deseados o específicos.
        Las “cookies” utilizadas por el Sitio Web, o el tercero que actúe en su nombre, se asocian
        únicamente con un usuario anónimo y su ordenador, y no proporcionan por sí los datos
        personales del usuario.<br>
        Las cookies son ficheros enviados a un navegador por medio de un servidor Web para
        registrar la navegación del Usuario en el Sitio Web, cuando el Usuario permita su
        recepción. A su vez podrá eliminar las “cookies” para lo cual deberá consultar las
        instrucciones de uso de su navegador.<br>
        Gracias a las cookies, resulta posible que “CORTINAS SOCOVOS” reconozca el navegador
        del ordenador utilizado por el Usuario con la finalidad de facilitar contenidos y ofrecer las
        preferencias de navegación u publicitarias que el Usuario, a los perfiles demográficos de
        los Usuarios así como para medir las visitas y parámetros del tráfico, controlar el progreso
        y número de entradas.<br>
        <h2>10. Duración y terminación</h2>
        La prestación del servicio del presente Sitio Web y los demás servicios tienen en principio
        una duración indefinida. No obstante, “CORTINAS SOCOVOS” podrá dar por terminada o
        suspender cualquiera de los servicios del portal. Cuando sea ello posible, “CORTINAS
        SOCOVOS” anunciará la terminación o suspensión de la prestación del servicio
        determinado.<br>
        <h2>11. Declaraciones y Garantías</h2>
        En general, los contenidos y servicios ofrecidos en el Sitio Web tienen carácter meramente
        informativo. Por consiguiente, al ofrecerlos, “CORTINAS SOCOVOS” no otorga garantía ni
        declaración alguna en relación con los contenidos y servicios ofrecidos en el Sitio web,
        incluyendo, a título enunciativo, garantías de licitud, fiabilidad, utilidad, veracidad,
        exactitud, o comerciabilidad, salvo en la medida en que por ley no puedan excluirse tales
        declaraciones y garantías.<br>
        <h2>12. Fuerza mayor</h2>
        “CORTINAS SOCOVOS” no será responsable en todo en caso de imposibilidad de prestar
        servicio, si ésta se debe a interrupciones prolongadas del suministro eléctrico, líneas de
        telecomunicaciones, conflictos sociales, huelgas, rebelión, explosiones, inundaciones, actos
        y omisiones del Gobierno, y en general todos los supuestos de fuerza mayor o de caso
        fortuito.<br>
        <h2>13. Resolución de controversias. Ley aplicable y jurisdicción</h2>
        Las presentes Condiciones Generales de Uso, así como el uso del Sitio Web, se regirán por
        la legislación española. Cualquier controversia será resuelta ante los tribunales de Murcia.
        En el supuesto de que cualquier estipulación de las presentes Condiciones Generales de
        Uso resultara inexigible o nula en virtud de la legislación aplicable o como consecuencia de
        una resolución judicial o administrativa, dicha inexigibilidad o nulidad no hará que las
        presentes Condiciones Generales de Uso resulten inexigibles o nulas en su conjunto. En
        dichos casos, “CORTINAS SOCOVOS” procederá a la modificación o sustitución de dicha
        estipulación por otra que sea válida y exigible y que, en la medida de lo posible, consiga el
        objetivo y pretensión reflejados en la estipulación original.<br>`
    },
    {
        tittle: 'Condiciones de compra',
        text: `CONDICIONES DE VENTA
        <div style='text-align: left'>
        Los precios y textos publicados en la página www.hogarsocovos.es están sujetos a variaciones
        sin previo aviso, inclusive especificaciones técnicas. Es obligación del cliente comprobar la
        mercancía que se le entrega.<br>
        En caso de cambios por defecto del producto HOGAR SOCOVOS pasará a retirar dicho producto y
        lo cambiará por otro en perfectas condiciones ó reembolsará el importe.<br>
        HOGAR SOCOVOS asumirá asimismo el gasto de envío del nuevo producto.<br>
        El riesgo de pérdida o daños en la mercancía se transmite al cliente desde el momento en que se
        produce la entrega en el domicilio del cliente.<br>
        HOGAR SOCOVOS no se hace responsable bajo ningún concepto de los daños ocasionados por
        el mal uso de las mercancías.<br>
        El Usuario será guiado en el trámite que debe seguirse para la celebración del contrato, así como
        para la identificación y corrección de los datos que haya facilitado para la celebración del contrato a
        través de los formularios creados al efecto.<br>
        HOGAR SOCOVOS no archivará el documento electrónico en el que se formalice cada
        contratación. Por el contrario, el Usuario acepta las presentes Condiciones Generales de
        Contratación, que permanecerán accesibles desde la página Web.<br>
        El contrato se formaliza en lengua española, de acuerdo con las presentes Condiciones.
        El Usuario podrá consultar las características esenciales de cada producto a través de su
        descripción en la página Web.<br>
        <h2>GARANTÍAS</h2>
        Usted cuenta con toda la garantía de HOGAR SOCOVOS, que cambiará ó reembolsará inmediata
        y automáticamente cualquier pedido que no sea de su satisfacción.<br>
        <h2>PROTECCIÓN DE DATOS PERSONALES</h2>
        El acceso de los Usuarios al portal y la compra de los productos ofrecidos a través de la página
        Web implica el tratamiento de datos de carácter personal. Para HOGAR SOCOVOS, reviste una
        gran importancia el cumplimiento de la normativa sobre protección de datos de carácter personal y
        sobre servicios de la sociedad de la información y comercio electrónico.<br>
        Generalmente, las personas que hagan uso de la página web lo hacen sin necesidad de facilitar
        ningún dato de carácter personal. No obstante, para acceder a los servicios, los Usuarios, en
        ciertos casos deberán facilitar ciertos datos de carácter personal. HOGAR SOCOVOS garantiza la
        confidencialidad de los datos de carácter personal facilitados por los Usuarios, según lo establecido
        en la normativa sobre Protección de Datos de Carácter Personal, y sobre Servicios de la Sociedad
        de la Información y de Comercio Electrónico.<br>
        ALFONSO GARCÍA MARTÍNEZ cumple la legislación vigente en materia de protección de datos,
        habiendo adoptado los procedimientos administrativos y técnicos necesarios para garantizar la
        seguridad de los datos personales que recopilamos.<br>
        En este sentido, el Usuario queda informado y presta su consentimiento para la incorporación de
        sus datos a los ficheros automatizados titularidad de ALFONSO GARCÍA MARTÍNEZ , domiciliado
        en la C/ DEL ÁGUILA Nº 4; 02006 ALBACETE debidamente inscritos ante el Registro General de
        Protección de Datos de Carácter Personal, y para el tratamiento automatizado de sus datos,
        consecuencia de la consulta, solicitud o contratación de cualquier servicio, o de cualquier
        transacción u operación realizada, al objeto de acceder a la información y a los servicios facilitados
        por ALFONSO GARCÍA MARTÍNEZ a través de la página web, y en su caso para el
        mantenimiento de la relación contractual, así como para el envío de ofertas o comunicaciones
        publicitarias y promocionales.<br>
        El Usuario manifiesta que todos los datos facilitados por él son ciertos y correctos, y se
        compromete a comunicar a ALFONSO GARCÍA MARTÍNEZ los cambios que se produzcan en los
        mismos. El Usuario tiene derecho a oponerse al tratamiento de cualquiera de sus datos que no
        sean imprescindibles para la celebración del contrato y a su utilización para cualquier finalidad
        distinta del mantenimiento de su relación contractual.<br>
        La política de privacidad de ALFONSO GARCÍA MARTÍNEZ garantiza al Usuario en todo caso la
        posibilidad de ejercitar su derecho de acceso, rectificación, cancelación y oposición de sus datos,
        notificándolo a ALFONSO GARCÍA MARTÍNEZ enviando un e-mail a la dirección de correo
        electrónico alfonsosocovos@gmail.com.<br>
        Asimismo, y en cumplimiento de la Ley 34/2002 de Servicios de la Sociedad de la Información y
        Comercio Electrónico, ALFONSO GARCÍA MARTÍNEZ exclusivamente enviará ofertas o
        comunicaciones publicitarias y promocionales por vía electrónica a su dirección de correo
        electrónico o a través de otro medio de comunicación electrónica equivalente, a aquellos Usuarios
        que hayan otorgado expresamente su consentimiento.<br>
        ALFONSO GARCÍA MARTÍNEZ informa al Usuario que podrá darse de baja de este tipo de
        comunicaciones, siguiendo las instrucciones que aparecen al final de todos nuestros correos
        electrónicos o comunicándolo por escrito a la dirección de correo electrónico
        alfonsosocovos@gmail.com .<br>
        <h2>DERECHOS Y OBLIGACIONES DE LAS PARTES</h2>
        a. Obligaciones de HOGAR SOCOVOS:<br>
        HOGAR SOCOVOS, se compromete a cumplir con las siguientes obligaciones contractuales
        derivadas de la relación comercial con el Usuario consecuencia de la contratación los servicios por
        parte del mismo:<br>
        Prestar con las máximas garantías al Usuario, el servicio solicitado por éste conforme a lo
        dispuesto en las condiciones de contratación, sin faltar a la buena fe contractual.<br>
        Informar expresamente al Usuario de la existencia de las presentes condiciones con carácter
        previo al inicio del procedimiento de contratación.<br>
        Informar al Usuario con carácter previo a la contratación y de manera concreta, clara, precisa e
        inequívoca, de las características específicas de los servicios solicitados, tales como el precio de
        los mismos y los impuestos que le sean de aplicación.<br>
        Poner a disposición del Usuario un ejemplar del texto de las condiciones generales.<br>
        b. Obligaciones del Usuario<br>
        Por su parte, el Usuario se compromete a:<br>
        Llevar a cabo el íntegro cumplimiento de lo establecido en las presentes condiciones de los
        servicios facilitados por HOGAR SOCOVOS.<br>
        Completar los formularios de registro previos al inicio del procedimiento de contratación con
        información veraz y actual.<br>
        Facilitar de forma correcta los datos bancarios solicitados por HOGAR SOCOVOS.<br>
        Pagar el precio de los productos adquiridos, sin que la presentación de una reclamación le exima
        de esta obligación.<br>
        c. Derechos del usuario:<br>
        Toda la información proporcionada al cliente tendrá el carácter de vinculante para el oferente en los
        términos establecidos por la legislación vigente.<br>
        Todo usuario tiene derecho a que los bienes que adquiera sean de la categoría y requerimientos
        legales contratados o de aquella calidad que guarde proporción directa con la categoría del
        establecimiento.<br>
        <h2>CONDICIONES DE USO</h2>
        El Usuario se compromete y garantiza hacer uso de la web conforme a las disposiciones
        establecidas en las condiciones generales de contratación, lo dispuesto en la normativa aplicable,
        así como lo relativo a la moral y a las buenas costumbres.<br>
        Mediante la aceptación de las presentes disposiciones, el usuario se compromete a usar la
        presente página web y los productos puestos a su disposición en la misma, de la manera y en la
        forma que en ella misma se establece. Quedando obligado a no utilizar la presente web y sus
        productos con fines ilícitos y/o contrarios a los fines establecidos en este aviso legal, que pudieran
        ser lesivos de derechos y/o intereses de terceros o que de cualquier forma puedan dañar la
        presente web o impedir su correcto funcionamiento o de los productos que en ella se ofrecen u
        ofrecieran en un futuro.<br>
        De igual forma, el Usuario se abstendrá de obtener los contenidos facilitados en la web por medios
        ilícitos, fraudulentos, robo o plagio de los mismos, de conformidad con lo establecido en el Código
        Penal y en la normativa de aplicación.<br>
        ALFONSO GARCÍA MARTÍNEZ se reserva el derecho a no conceder el acceso a la web, sin
        previo aviso, a cualquier Usuario que contravenga lo dispuesto en las presentes condiciones
        generales de contratación.<br>
        Por su parte, el Usuario, se compromete a no hacer un uso ilícito de los contenidos de la web, o
        que pueda suponer un perjuicio a ALFONSO GARCÍA MARTÍNEZ Por tanto, el Usuario se
        abstendrá a título no limitativo, de modificar, copiar, distribuir, publicar, ceder y/o a vender cualquier
        información o apariencia concerniente a la web titularidad de ALFONSO GARCÍA MARTÍNEZ.<br>
        a. Cookies y entorno seguro<br>
        Actualmente no se utilizan cookies en la página web.<br>
        ALFONSO GARCÍA MARTÍNEZ , a través de la página web, utiliza medios de tecnología punta
        para garantizar la protección de la información. HOGAR SOCOVOS garantiza que la contratación
        de los productos, se realiza en un entorno seguro.<br>
        b. Hipervínculos<br>
        ALFONSO GARCÍA MARTÍNEZ no es responsable de las páginas web enlazadas a esta, por lo
        que no se hace responsable de sus contenidos. Los riesgos derivados de la consulta de tales
        páginas web corresponden exclusivamente a los usuarios, que deberán regirse por los términos,
        condiciones y avisos legales de las mismas, de los que igualmente ALFONSO GARCÍA
        MARTÍNEZ no se hace responsable.<br>
        c. Propiedad Intelectual e Industrial<br>
        © HOGAR SOCOVOS es titular de los derechos de Propiedad Intelectual e Industrial de los
        elementos que integran el diseño de la web, como la marca, nombre comercial o signo distintivo.
        En particular y a título no limitativo, están protegidos por los derechos de autor, los logos,
        combinaciones de colores, la selección y la forma de presentación, el código fuente de la página
        web, los menús, los botones de navegación, el código HTML, los applets de Java, los textos,
        imágenes, gráficos, así como cualquier otro contenido de la página web titularidad de ALFONSO
        GARCÍA MARTÍNEZ.<br>
        El Usuario se compromete a respetar los derechos de propiedad intelectual e Industrial de la
        página web; por ello, el Usuario se abstendrá de copiar, reproducir, distribuir, poner a disposición o
        comunicación pública del contenido de la web, sin la autorización expresa y por escrito de
        ALFONSO GARCÍA MARTÍNEZ.<br>
        d. Limitación de responsabilidad<br><br>
        El Usuario exonera expresamente a HOGAR SOCOVOS de toda responsabilidad por daños
        directos e indirectos derivados de eventuales errores en la web, así como por la eventual
        interrupción, suspensión, retardo o anomalía en el acceso a la misma.<br>
        ALFONSO GARCÍA MARTÍNEZ se compromete a poner el máximo cuidado para preservar la web
        de cualesquiera virus, troyanos y demás elementos que pudieran vulnerar o dañar el sistema
        informático del Usuario o de terceros. En cualquier caso, el Usuario exonera de toda
        responsabilidad a ALFONSO GARCÍA MARTÍNEZ por los daños directos o indirectos causados
        por la eventual existencia de tales elementos dañinos o maliciosos.<br>
        <h2>PERIODO DE VALIDEZ DE LAS PRESENTES CONDICIONES</h2>
        El periodo de validez de las presentes condiciones, será el tiempo que permanezcan publicadas en
        la página Web y se aplicarán a los servicios adquiridos en el momento en que dichas condiciones
        estuvieran disponibles.<br>
        En todo caso, ALFONSO GARCÍA MARTÍNEZ se reserva el derecho de modificarlas de forma
        unilateral, sin que ello pueda afectar a los servicios contratados por los usuarios con carácter
        previo a la modificación, salvo en aquellos supuestos en los que el usuario hubiera cambiado o
        modificado el servicio contratado, en cuyo caso resultarán de aplicación las condiciones vigentes
        en el momento del cambio y/o modificación.<br>
        <h2>DEVOLUCIONES</h2>
        Si durante los siete días posteriores a la recepción del pedido el cliente desea devolver el producto,
        nuestro Departamento de Atención al Cliente realizará las gestiones necesarias para tramitar la
        devolución bajo las siguientes condiciones:<br>
        - Los productos deberán devolverse en su embalaje y estado original. El cliente dispondrá de siete
        días hábiles desde la recepción del pedido para resolver el contrato sin incurrir en penalización y
        siéndole devuelto el importe íntegro de su compra, pero corriendo el cliente con los costes de los
        portes correspondientes.<br>
        - Productos defectuosos o deteriorados. Si el cliente detecta que el producto recibido se encuentra
        defectuoso o deteriorado, deberá ponerse en contacto con el Departamento de Atención al Cliente
        en los siete días hábiles posteriores a la recepción del pedido, donde se realizarán las gestiones
        necesarias para reemplazarlo por uno nuevo en perfecto estado y sin ningún cargo adicional.
        Si el producto no se encuentra disponible, la empresa procederá a devolver el importe que el
        cliente haya abonado, incluidos los gastos de envío, de acuerdo a la forma de pago utilizada en la
        compra.<br>
        <h2>CANCELACIONES</h2>
        Las cancelaciones serán aceptadas en pedidos que no hayan sido enviados al domiciliado indicado
        por el cliente. Si la mercancía ha sido enviada será considerado devolución (Ver apartado
        Devoluciones).<br>
        La empresa acepta la cancelación de órdenes, ya sea a través de su página web o través de
        cualquiera de los medios de acceso al Departamento de Atención al Cliente.<br>
        NULIDAD E INEFICACIA DE LAS CLÁUSULAS<br>
        Si cualquiera cláusula incluida en estas condiciones fuese declarada total o parcialmente nula o
        ineficaz, tal nulidad afectara tan sólo a dicha disposición o la parte de la misma que resulte nula o
        ineficaz, subsistiendo en todo lo demás las condiciones.<br>
        <h2>FUEROS</h2>
        En el caso de litigio, las partes contratantes se someten a los juzgados y Tribunales de Albacete
        con expresa renuncia de cualquier otro fuero que pudiera corresponderles, asumiendo la parte que
        incumpla el contrato los gastos judiciales y extrajudiciales que se deriven de la reclamación incluido
        los gastos de abogados, procuradores, etc.</div>`
    },
    {
        tittle: 'Envíos y devoluciones',
        text: `<div style='text-align: left'><h2>FORMA DE ENVIO</h2>
        a) Zona de entrega<br>
        HOGAR SOCOVOS realizará la entrega en Península, Baleares y Canarias en un plazo de entre 2
        y 10 días (si no hay existencias del articulo pedido en nuestros almacenes se puede demorar la entrega) una vez realizado el cargo sobre la tarjeta de crédito o débito o de recibida la
        transferencia de pago por parte del cliente o una vez autorizado el mismo por parte de la entidad
        bancaria.<br>
        HOGAR SOCOVOS garantiza el envío en los plazos y zonas anteriormente indicadas de todos los
        productos que tenemos en stock para todos los pedidos que sean recibidos en nuestras oficinas
        antes de las 14:00 horas, una vez es autorizado el pago. Los pedidos recibidos en sábados,
        domingos y festivos se considerarán recibidos el primer día laborable posterior al mismo.<br>
        b) Gastos de envío<br>
        Los gastos de envío de la mercancía corren por cuenta del cliente. Para envíos dentro de la
        Península, el cargo fijo es de 6,00 € (SEIS EUROS). Para envíos a Baleares y Canarias, los costes de envío dependerán de las medidas de la valija a remitir (Consultar por email: alfonsosocovos@gmail.com o por tel. 967617766). 
        Aproximadamente para paquetes con unas medidas de hasta 40 x 60 x 10 centímetros el coste será de 8,50 € (OCHO EUROS Y CINCUENTA CÉNTIMOS DE EURO). Para valijas con unas medidas de hasta
        40 x 60 x 60 centímetros, el coste será de 15,00 € (QUINCE EUROS).<br>
        
        <h2>DEVOLUCIÓN DE SU PEDIDO</h2>
        Si usted no está satisfecho con su compra, usted dispondrá de un plazo de siete días desde la recepción de la devolución del artículo o artículos, (De acuerdo con el artículo 44 de la Ley 7/1996, de 15 de enero de Gestión Minorista modificada por la Ley 47/2002 de 19 de diciembre).<br>
        Simplemente devuelva el producto en su embalaje original previo aviso al correo electrónico: alfonsosocovos@gmail.com o por teléfono (967 61 77 66), incluyendo garantías, etiquetas, etc. No se aceptarán devoluciones de productos manipulados o utilizados. Los gastos de envío de devolución será pagado por el CLIENTE.<br>
        En ningún caso se aceptará un paquete enviado a portes debidos. Es muy importante que para devolver/cambiar los productos y/o devolución estén perfectamente embalados. En caso de que el producto /s estén dañados debido a un embalaje inapropiado, no admitiremos su devolución.<br>
        Al revisar y verificar que el producto está en perfectas condiciones se procederá al reintegro de la cantidad, de acuerdo con el método de pago que fue procesado, y si es así, se procederá al envío del nuevo producto.<br>
        En el caso de artículos defectuosos o erróneos de la tienda, todos los gastos de envío correrán a cargo de HOGAR SOCOVOS.</div>`
    },
    {
        tittle: 'Formas de pago',
        text: `<div style='text-align: left'>Los precios y tarifas aplicables a la contratación por parte del usuario de los servicios, serán los
        que figuren en la página Web titularidad de HOGAR SOCOVOS: www.hogarsocovos.es, en el
        momento en el que el usuario acceda al servicio específico, y proceda a iniciar el proceso de
        contratación.<br>
        HOGAR SOCOVOS se reserva el derecho a modificar los precios de los productos publicados en
        la página Web mencionada anteriormente, cuando así lo considere conveniente y haciéndolo
        visible en la página Web.<br>
        Precios e Impuestos aplicados
        Los precios aplicables son los que figuran en la Web en el momento en que el cliente realiza su
        pedido.<br>
        El precio de los productos que aparecen en la Web, incluye el Impuesto de Valor Añadido (IVA)
        correspondiente según el producto.<br><br>
        
        a) Tarjetas:
        · VISA
        · AMERICAN EXPRESS
        · MasterCard
        · Diners Club
        Se realizar un cargo automático en su tarjeta, en el momento en que procese el pedido. Esta
        operación se realizará a través de un servidor seguro de HOGAR SOCOVOS, quien garantiza la
        confidencialidad de los datos facilitados por el cliente mediante encriptación, evitando su
        comunicación a terceros extraños a la relación jurídica, conforme a lo establecido en la Ley 15/99
        de Protección de Datos de Carácter Personal. La empresa podrá solicitar al cliente datos
        complementarios que permitan verificar que la compra ha sido realizada por el titular de la tarjeta.<br><br>
        b) Transferencia Bancaria<br>
        Se podrá realizar el pago a través de una transferencia bancaria a favor de ALFONSO GARCÍA
        MARTÍNEZ, al número de cuenta (poner los 20 dígitos del número de cuente a la que realizar la
        transferencia bancaria). En caso de realizar el pago mediante este sistema, el envío de la
        mercancía no se tramitará hasta recibir confirmación de la recepción del pago por parte de la
        entidad bancaria receptora de la misma.<br><br>
        c) Transferencia Bancaria y/o Ingreso en cuenta<br>
        Esta modalidad, le permite realizar el pago de forma sencilla para compras a través de Internet, ya que usted puede efectuar dicho pago desde su entidad bancaria. Seguidamente le enviaremos un email, cuando hayamos recibido el la transferencia y/o ingreso. Deberá indicarnos la referencia facilitada de su pedido, para agilizar su compra.<br><br>
        d)Tarjeta de Credito<br>
        Puede pagar fácilmente sus compras con tarjeta de crédito o débito. Con total seguridad.</div>`
    },
]