import { types } from "../types/types";

const initialState = {
    sizes: [],
    done: false
}

export const sizeReducer = ( state = initialState, action ) => {
    switch(action.type) {
       case types.sizesSet:
           return {
               ...state,
               sizes: action.payload
           }
        case types.sizeFinishNew:
            return {
                ...state,
                sizes: [...state.sizes, action.payload]
            }
        case types.sizeDeleted:
            return {
                ...state,
                sizes: state.sizes.filter(c => (
                        c.id !== action.payload) 
                )
            }
        case types.sizeUpdated:
            return {
                ...state,
                sizes: state.sizes.map(e => (
                     e.id === action.payload.id) ? action.payload : e
                )
            }
        default:
            return state;
    }
}