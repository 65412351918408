import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getArticlesBySearch } from '../../actions/articles';
import { Article } from './Article';
import Typography from '@material-ui/core/Typography';

export const ArticlesSearch = () => {
    const dispatch = useDispatch();
    const { searchId } = useParams();

    useEffect(() => {     
        if(!!searchId)   {
            dispatch(getArticlesBySearch(searchId));
        }
    }, [dispatch, searchId]);

    const {articles} = useSelector(state => state.article);
    return (
        <div className="articles">
            {   articles.map((ar) => (
                    <Article article={ar} key={ar._id}/>
                ))
            }
            {
                articles && articles.length===0 && 
                <Typography variant="h4" gutterBottom>
                    No hay resultados para {searchId}
                </Typography>
            }
        </div>
    )
}
