import React from 'react';
import MaterialTable from 'material-table';
import { useSelector } from 'react-redux';

export const ColorAdminScreen1 = (props) => {
    const { colors } = useSelector( state => state.color);
    
    return (
        <>
            {(colors.length > 0) &&
                <MaterialTable
                    title="Listado de Colores"
                    data={colors}
                    columns={[
                    { title: 'Nombre', field: 'name' }
                    ]}
                    options={{
                        pageSize: 10}}
                    actions={[
                        {
                            icon: 'delete',
                            tooltip: 'Borrar color',
                            onClick: (event, rowData) => props.deleteColor({name: rowData.name, id:rowData.id})
                        }
                    ]}
                />
            }
        </>
    )
}
