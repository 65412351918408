import React, { useEffect, useState } from 'react'
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Orders } from './Orders';
import { useDispatch } from 'react-redux';
import { getClientOrders } from '../../actions/orders';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center'
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  }));

export const Space = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientOrders());
      }, [dispatch]);

    return (
        <div>
            <div className="content-title">
                <Typography variant="h4" gutterBottom>
                    Mi cuenta
                </Typography>
            </div>
                <div className={classes.root}>
                    <Orders />
                </div>
        </div>
    )
}
