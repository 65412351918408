import { types } from "../types/types";

const initialState = {
    articles: [],
    articlesByCategory: [],
    articleSelected: null
}

export const articleReducer = ( state = initialState, action ) => {
    switch(action.type) {
       case types.articlesSet:
           return {
               ...state,
               articles: action.payload
           }
        case types.articleFinishNew:
            return {
                ...state,
                articles: [...state.articles, action.payload]
            }
        case types.articleDeleted:
            return {
                ...state,
                articles: state.articles.filter(c => (
                        c.id !== action.payload) 
                )
            }
        case types.articleUpdated:
            return {
                ...state,
                articles: state.articles.map(e => (
                     e.id === action.payload.id) ? action.payload : e
                )
            }
        case types.articleSelected:
            return {
                ...state,
                articleSelected: action.payload
            }
        case types.subArticleDeleted:
            return {
                ...state,
                articleSelected: {
                    ...state.articleSelected,
                    articulosDetalle: state.articleSelected.articulosDetalle.filter(c => (
                    c.id !== action.payload))
                }                
            }
        case types.imageDeleted:
            return{
                ...state,
                articleSelected: {
                    ...state.articleSelected,
                    articulosDetalle: state.articleSelected.articulosDetalle.map(artD => ({
                       ...artD,
                       imgs: artD.imgs.filter(img => img!==action.payload)
                    }))
                }
            }
        case types.articlesByCategorySet:
            return {
                ...state,
                articlesByCategory: action.payload
            }
        default:
            return state;
    }
}