import React from 'react'
import { MainHeader } from './MainHeader'
import { TopHeader } from './TopHeader'

export const HeadScreen = () => {
    return (
        <>
            <TopHeader />
            <MainHeader />
        </>
    )
}
