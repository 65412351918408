import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Categories } from './Categories';
import { Articles } from './Articles';
import Typography from '@material-ui/core/Typography';

export const Categorie = () => {
    const { categorieId } = useParams();
    const {textRoute} = useSelector(state => state.navigation);
    const { categories } = useSelector( state => state.category);
    const categorie = categories.find((t) => t.route === categorieId);
    const superCategory = !!categories.find(c => c.superCategory?.route === categorieId);
    return (
        <div>
            <div className="route-title">
                <Typography gutterBottom variant="h5" component="h2">
                    {textRoute}
                </Typography>
            </div>
            {superCategory && <Categories categorieId={categorieId} />}
            {!superCategory && <Articles categorie={categorie}/>}
        </div>
    )
}
