import { fetchConToken } from "../helpers/fetch";
import { types } from './../types/types';
import Swal from "sweetalert2";
import { doneTrue } from "./ui";

export const getColors = () => {
    return async(dispatch) => {
        const resp = await fetchConToken('colors', 'GET');
        const body = await resp.json();
        if(body.ok) {
            dispatch(setColors(
                body.colores
            ));
        } else {
            Swal.fire('Error', body.msg, 'error');
        }
    }
}


const setColors = (data) => ({
    type: types.colorsSet,
    payload: data
});


export const colorStartDelete = (id) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`colors/${id}`, {},'DELETE');
            const body = await resp.json();

            if(body.ok) {
                dispatch(colorDeleted(id));
                dispatch(doneTrue());
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error, 'error');
        }
    }
}

const colorDeleted = (id) => ({
    type: types.colorDeleted,
    payload: id
});


export const colorStartAddNew = (event) => {
    return async(dispatch) => {
        try {
            const resp = await fetchConToken('colors', event, 'POST');
            const body = await resp.json();
            if(body.ok) {
                dispatch(colorFinishNew(body.color));
                dispatch(doneTrue());
            } else{
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error.toString(), 'error');
        }
    }
}

const colorFinishNew = (color) => ({
    type: types.colorFinishNew,
    payload: color
});