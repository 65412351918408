import { fetchConToken } from "../helpers/fetch";
import { types } from './../types/types';
import Swal from "sweetalert2";
import { doneTrue } from "./ui";

export const getSizes = () => {
    return async(dispatch) => {
        const resp = await fetchConToken('sizes', 'GET');
        const body = await resp.json();
        if(body.ok) {
            dispatch(setSizes(
                body.tallas
            ));
        } else {
            Swal.fire('Error', body.msg, 'error');
        }
    }
}


const setSizes = (data) => ({
    type: types.sizesSet,
    payload: data
});


export const sizeStartDelete = (id) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`sizes/${id}`, {},'DELETE');
            const body = await resp.json();

            if(body.ok) {
                dispatch(sizeDeleted(id));
                dispatch(doneTrue());
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error, 'error');
        }
    }
}

const sizeDeleted = (id) => ({
    type: types.sizeDeleted,
    payload: id
});


export const sizeStartAddNew = (event) => {
    return async(dispatch) => {
        try {
            const resp = await fetchConToken('sizes', event, 'POST');
            const body = await resp.json();
            if(body.ok) {
                dispatch(sizeFinishNew(body.talla));
                dispatch(doneTrue());
            } else{
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error.toString(), 'error');
        }
    }
}

const sizeFinishNew = (size) => ({
    type: types.sizeFinishNew,
    payload: size
});