
export const mountForm = ({formValues, subArticles, images}) => {
    subArticles = subArticles.map(sub => {
        return {
            ...sub,
            color: sub.color && sub.color.trim() !== '' ? sub.color : null,
            size: sub.size && sub.size.trim() !== '' ? sub.size : null,
        }
    });

    const subArticleDefatult = {
        'big': formValues.big,
        'color': formValues.color && formValues.color.trim() !== '' ? formValues.color : null,
        'price': formValues.price,
        'size': formValues.size && formValues.size.trim() !== '' ? formValues.size : null,
        'stock': formValues.stock,
        'id': !!formValues.id_default ? formValues.id_default : null,
        'default': true,
        'imgs': images
    }

    const articlesDetail = [subArticleDefatult,...subArticles];

    return {
        'id': !!formValues.id ? formValues.id : null,
        'name': formValues.name,
        'description': formValues.description,
        'idCategoria': formValues.category,
        articlesDetail
    };
}
