import React, { useEffect, useState } from 'react'
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { sizeStartAddNew } from '../../../actions/sizes';

const initEvent = {
    name: '',
};

export const CreateSize = ({cancel}) => {

    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState(initEvent);
    const [isFormValid, setIsFormValid] = useState(false);
    const {name} = formValues;

    useEffect(() => {
        if(name.trim() !== '') {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [name]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(sizeStartAddNew(formValues));
        cancel();
    }

    const handleInputChange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.name]:target.value
        })
    }


    return (
        <div>
            <Container maxWidth="lg">
                <h1 className='create-title'>Crear talla</h1>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className='create-category-container'>
                            <form className='create-category-form' noValidate autoComplete="off" onSubmit={handleSubmit}>
                                <TextField id="standard-basic" label="*Nombre" name="name" value={name} onChange={handleInputChange}/>
                                <div className='create-category-form-buttons'>
                                    <Button variant="contained" color="primary" disabled={!isFormValid} type="submit">
                                        Crear
                                    </Button>
                                    <Button variant="contained" onClick={() => cancel()}>
                                        Cancelar
                                    </Button>
                                </div>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
