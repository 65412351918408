import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { CategoryAdminScreen1 } from './CategoryAdminScreen1';
import Swal from "sweetalert2";
import { CreateCategory } from './CreateCategory';
import { categoryStartDelete } from '../../../actions/categories';
import { useDispatch } from 'react-redux';

export const CategoryAdminScreen = () => {
  const [showCreate, setShowCreate] = useState(false);
  const [categorySelected, setCategorySelected] = useState();
  const dispatch = useDispatch();

  const editCategory = (category) => {

    /*if(category.superCategory) {
      //const superCategory = category.superCategory;

      category.superCategory = category.superCategory._id;
    }*/

    setCategorySelected(category);
    setShowCreate(true);
  }

  const deleteCategory = ({name, id}) => {
    Swal.fire({
      title: `Borrar categoria ${name}`,
      text: "¿Estas seguro de borrar esta categoria?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3d4c6f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(categoryStartDelete(id));
      }
    })
  }

  const openCreateModal = () => {
    setCategorySelected(null);
    setShowCreate(true);
  }

  const cancelCreateCategory = () => {
    setShowCreate(false);
  }

  return (
    <div>
      {!showCreate && 
        <>
          <CategoryAdminScreen1 editCategory={editCategory} deleteCategory={deleteCategory}/>
          <div className="add-button-div">
            <IconButton color="secondary" aria-label="add an alarm" onClick={openCreateModal}>
              <AddCircleIcon className="add-button"/>
            </IconButton>
          </div>
        </>
      }
      {showCreate && <CreateCategory category={categorySelected} cancel={cancelCreateCategory}/>}
    </div>
  );
}
