import { fetchConToken, fetchFormConToken, fetchSinToken } from "../helpers/fetch";
import Swal from "sweetalert2";
import { types } from './../types/types';
import { doneTrue } from "./ui";
import { setLoading } from '../actions/ui';

export const getArticles = () => {
    return async(dispatch) => {
        const resp = await fetchConToken('articles', 'GET');
        const body = await resp.json();
        if(body.ok) {

            dispatch(setArticles(
                body.articulos
            ));
        } else {
            Swal.fire('Error', body.msg, 'error');
        }
    }
}

const setArticles = (data) => ({
    type: types.articlesSet,
    payload: data
});


export const getArticlesBySearch = (id) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const resp = await fetchConToken(`articles/search/${id}`, {},'GET');
            const body = await resp.json();
            dispatch(setLoading(false));
            if(body.ok) {
                dispatch(setArticles(
                    body.articulos
                ));
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error, 'error');
        }
    }
}


export const articleStartDelete = (id) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`articles/${id}`, {},'DELETE');
            const body = await resp.json();

            if(body.ok) {
                dispatch(articleDeleted(id));
                dispatch(doneTrue());
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error, 'error');
        }
    }
}

const articleDeleted = (id) => ({
    type: types.articleDeleted,
    payload: id
});

export const articleStartAddNew = ({form}) => {
    return async(dispatch) => {
        const articleImages = form.articlesDetail.map(art => art.imgs);
        form.articlesDetail.forEach( art => delete art.imgs );
        try {
            const resp = await fetchConToken('articles', form, 'POST');
            const body = await resp.json();

            if(body.ok) {
                dispatch(articleFinishNew(body.articulo));

                body.articulosDetalle.forEach((art, index) => {
                    if(!!articleImages[index]) {
                        Array.from(articleImages[index]).forEach(file => {
                            dispatch(articleUploadFile({id: art.id , file}));
                        });
                    }
                });
                dispatch(doneTrue());               
            } else{
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error, 'error');
        }
    }
}

const articleFinishNew = (article) => ({
    type: types.articleFinishNew,
    payload: article
});

export const articleStartEdit = ({form}) => {
    return async(dispatch) => {
        const articleImages = form.articlesDetail.map(art => art.imgs);
        form.articlesDetail.forEach( art => delete art.imgs );
        try {
            const resp = await fetchConToken(`articles/${form.id}`, form, 'PUT');
            const body = await resp.json();

            if(body.ok) {
                dispatch(articleUpdated(body.articulo));
                body.articulosDetalle.forEach((art, index) => {
                    if(!!articleImages[index]) {
                        Array.from(articleImages[index]).forEach(file => {
                            dispatch(articleUploadFile({id: art.id , file}));
                        });
                    }
                });   
                dispatch(doneTrue());   
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error.toString(), 'error');
        }
    }    
}

const articleUpdated = (event) => ({
    type: types.articleUpdated,
    payload: event
});

export const  getArticleById = (id) => {
    return async(dispatch) => {
        const resp = await fetchSinToken(`articles/${id}`, 'GET');
        const body = await resp.json();
        if(body.ok) {

            dispatch(setArticleSelected(
                body.articulo
            ));
        } else {
            Swal.fire('Error', body.msg, 'error');
        }
    }
}

const setArticleSelected = (event) => ({
    type: types.articleSelected,
    payload: event
});

export const subArticleStartDelete = (id) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`articles/detail/${id}`, {},'DELETE');
            const body = await resp.json();
            if(body.ok) {
                dispatch(subArticleDeleted(id));
                dispatch(doneTrue());
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error, 'error');
        }
    }
}

const subArticleDeleted = (id) => ({
    type: types.subArticleDeleted,
    payload: id
});


const articleUploadFile = ({id, file}) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFormConToken(`upload/articles/${id}`, {archivo: file},'PUT');
             await resp.json();
        } catch (error) {
            Swal.fire('Error', error, 'error');
        }
    }
};

export const deleteImage = (img) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`images/articles/${img}`, {},'DELETE');
            const body = await resp.json();
            if(body.ok) {
                dispatch(imageDeleted(img));
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error, 'error');
        }
    }
}

const imageDeleted = (img) => ({
    type: types.imageDeleted,
    payload: img
});

export const getArticlesByCategory = (cat) => {
    return async(dispatch) => {
        const resp = await fetchSinToken(`articles/categorie/${cat}`, 'GET');
        const body = await resp.json();
        if(body.ok) {
            dispatch(setArticlesByCategory(
                body.articulos
            ));
        } else {
            Swal.fire('Error', body.msg, 'error');
        }
    }
}

const setArticlesByCategory = (data) => ({
    type: types.articlesByCategorySet,
    payload: data
});
