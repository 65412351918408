import React from 'react'
import { useSelector } from 'react-redux';
import { Articles } from './Articles';
import Typography from '@material-ui/core/Typography';
import { useHistory, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';

export const SubCategorie = () => {
    const {textRoute, subTextRoute, categorieRoute} = useSelector(state => state.navigation);
    const history = useHistory();
    const { subCategorieId } = useParams();

    const { categories } = useSelector( state => state.category);
    const categorie = categories.find((t) => t.route === subCategorieId);

    const goToParent = () => {
        history.push(`/categorie/${categorieRoute}`);
    }

    return (
        <div>
            <div className="route-title">
                <div className="flex-row">
                    <Typography gutterBottom variant="h5" component="h2" className="route-title-first" onClick={goToParent}>
                        {textRoute}
                    </Typography>
                    {!!subTextRoute && <Typography gutterBottom variant="h5" component="h2">
                        /
                    </Typography>}
                    <Typography gutterBottom variant="h5" component="h2">
                        {subTextRoute}
                    </Typography>
                </div>
                <Button color="secondary" variant="contained" onClick={goToParent}>
                            Volver
                        </Button>
            </div>
            <Articles categorie={categorie}/>
        </div>
    )
}
