import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import { getClientOrderById } from '../../../actions/orders';
import Button from '@material-ui/core/Button';

export const CreateOrder = ({order, goBack}) => {
    let { orderDetail } = useSelector( state => state.client);
    const [showOrdersDetail, setshowOrdersDetail] = useState([]);
    const [ titleTableOrder , setTitleTableOrder] = useState('');
    const [ titleTableOrder1 , setTitleTableOrder1] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        setTitleTableOrder(`Detalle del pedido ${order.id} / Precio total: ${order.total}`);
        setTitleTableOrder1(`Enviado a: ${order.name}, ${order.adress} // Teléfono: ${order.phone}`);
        dispatch(getClientOrderById(order.id));
    }, [order, dispatch])

    const viewTable = () => {
        goBack();
    }

    useEffect(() => {
        if(!!orderDetail) {
            setshowOrdersDetail(
                orderDetail.map(or => ({
                    ...or,
                    total: or.total + ' €',
                    price: or.price + ' €'
                }))
            )
        }
    }, [orderDetail])

    return (
        <div className="client-orders-table">
            <div className="order-button">
            <Button variant="contained" color="primary" onClick={viewTable}>
                Volver
            </Button>
            </div>
                <h3>{titleTableOrder}</h3>
                <h4>{titleTableOrder1}</h4>
                <MaterialTable
                    title="Articulos"
                    data={showOrdersDetail}
                    options={{pageSize: 10}}
                    columns={[
                        { title: 'Nombre', field: 'name'},
                        { title: 'Cantidad', field: 'amount'},
                        { title: 'Color', field: 'color' },
                        { title: 'Talla', field: 'size' },
                        { title: 'Precio', field: 'price' },
                        { title: 'Total', field: 'total' }
                    ]}
                />
        </div>
    )
}

