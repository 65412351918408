import React, { useEffect, useState } from 'react'
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import { articleStartAddNew, articleStartEdit, deleteImage, getArticleById, subArticleStartDelete } from '../../../actions/articles';
import { SubArticle } from './SubArticle';
import { mountForm } from './helpers';
import { ImageCarrousel } from './ImageCarrousel';

const initEvent = {
    name: '',
    description: '',
    price: '',
    category: '',
    color: '',
    size: '',
    img: '',
    stock: true,
    big: false
};

export const CreateArticle = ({article, cancel}) => {
    const [isFormValid, setIsFormValid] = useState(false);
    const { categories } = useSelector( state => state.category);
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState(initEvent);
    const {name, description, category, price, imgs, color, size, stock, big} = formValues;
    const [subArticles, setSubArticles] = useState([]);
    const { colors } = useSelector( state => state.color);
    const { sizes } = useSelector( state => state.size);
    const [images, setImages] = useState('');

    const isEdit = !!article;
    useEffect(() => {
        if(isEdit) {
            dispatch(getArticleById(article.id));
        } 
      }, [dispatch, article, isEdit]);
    const {articleSelected} = useSelector(state => state.article);
    useEffect(() => {
        const sArr = subArticles.find(s => !s.price);
        if(name.trim() !== '' && description.trim() !== '' && category.trim() !== '' && price.toString().trim() !== '' && !sArr) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [name, description, category, price, subArticles]);

    useEffect(() => {
        if(isEdit && !!articleSelected) {
            const articleDetail = articleSelected;
            const [articleDefault] = articleDetail.articulosDetalle.filter(aD => !!aD.default);
            let articlesRest = articleDetail.articulosDetalle.filter(aD => !aD.default);

            articlesRest = articlesRest.map(art => ({
                ...art,
                images: art.imgs,
                size: art.size ? art.size._id : '',
                color: art.color ? art.color._id : ''
            }));

            setFormValues({
                id: articleDetail._id,
                name: articleDetail.name,
                description: articleDetail.description,
                category: articleDetail.idCategoria,
                price: articleDefault.price,
                color: articleDefault.color?._id,
                size: articleDefault.size?._id,
                stock: articleDefault.stock,
                big: articleDefault.big,
                id_default: articleDefault.id,
                imgs: articleDefault.imgs
            });
            setSubArticles(articlesRest);
        } else {
            setFormValues(initEvent);
        }
    }, [isEdit, setFormValues, article, articleSelected]);

    const parentCategories = categories.filter(cat => !!cat.superCategory).map(paCat => paCat.superCategory._id);
    const categoriesToShow = categories.filter(cat => !parentCategories.includes(cat.id));
    const handleInputChange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.name]:target.value
        })
    }


    const handleSelectChange = (event) => {
        handleInputChange({target: {name: 'category', value: event.target.value}});
    }

    const handleImageChange = (event) => {
        setImages(event.target.files);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formMounted = mountForm({formValues, subArticles, images});
        if(isEdit) {
            dispatch(articleStartEdit({form: formMounted}));
        } else {
            dispatch(articleStartAddNew({form: formMounted}));
        }
        cancel();
    }

    const subArticleChange = (e) => {
        if(e.id) {
            setSubArticles(subArticles.map(sub => (sub.id === e.id) ? e : sub));
        }
        
        if(e.ids) {
            setSubArticles(subArticles.map(sub => (sub.ids === e.ids) ? e : sub));
        }
    }

    const addSubArticle = () => {
        setSubArticles([...subArticles, {
            ids: new Date().getTime(),
            size: '',
            color: '',
            price: '',
            stock: true,
            big: false,
            imgs: ''
        }]);
    }

    const handleChecked = ({target}) => {
        setFormValues({
            ...formValues,
            [target.name]:target.checked
        });
    }

    const deleteSubArticle = (article) => {
        if(article.ids) {
            // El subArticulo se acaba de añadir
            setSubArticles(subArticles.filter(sub => sub.ids !== article.ids));
        } else {
            // El subArticulo ya venia de antes
            dispatch(subArticleStartDelete(article.id));
        }
    }

    const handleDeleteImage = (img) => {
        dispatch(deleteImage(img));
    }
    
    return (
        <div>
            <Container maxWidth="lg">
                <h1 className='create-title'>{isEdit ? 'Editar Artículo' : 'Crear Artículo'}</h1>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className='create-category-container'>
                            <form className='create-category-form' noValidate autoComplete="off" onSubmit={handleSubmit}>
                                <TextField id="standard-basic" label="*Nombre" name="name" value={name} onChange={handleInputChange}/>
                                <TextField id="standard-basic" label="*Descripción" name="description" value={description} onChange={handleInputChange}/>
                                <FormControl  aria-label="position">
                                    <InputLabel id="demo-simple-select-label">Categoría</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={category}
                                        onChange={handleSelectChange}
                                    >
                                        <MenuItem value="">
                                            <em>----</em>
                                        </MenuItem>
                                        {categoriesToShow.map(cat => <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>)}
                                    </Select>
                                </FormControl >
                                <FormControl  aria-label="position">
                                    <InputLabel id="demo-simple-select-label">Medida</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={size}
                                        name="size"
                                        onChange={handleInputChange}
                                    >
                                        <MenuItem value="">
                                            <em>----</em>
                                        </MenuItem>
                                        {sizes.map(size => <MenuItem key={size.id} value={size.id}>{size.name}</MenuItem>)}
                                    </Select>
                                </FormControl >
                                <FormControl  aria-label="position">
                                    <InputLabel id="demo-simple-select-label">Color</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={color}
                                        name="color"
                                        onChange={handleInputChange}
                                    >
                                        <MenuItem value="">
                                            <em>----</em>
                                        </MenuItem>
                                        {colors.map(color => <MenuItem key={color.id} value={color.id}>{color.name}</MenuItem>)} 
                                    </Select>
                                </FormControl >                                                                
                                <TextField id="standard-basic" label="*Precio" name="price" value={price} type="number" onChange={handleInputChange}/>
                                <div className="form-checks">
                                    <FormControlLabel
                                        control={<Checkbox checked={stock} onChange={handleChecked} name="stock" />}
                                        label="Stock"
                                    />     
                                    <FormControlLabel
                                        control={<Checkbox checked={big} onChange={handleChecked} name="big" />}
                                        label="Artículo Grande"
                                    />
                                </div>
                                <ImageCarrousel images={imgs} deleteImage={handleDeleteImage}/>
                                <input className="create-cagegory-image" type="file" id="file" multiple name="file" onChange={handleImageChange}/>
                                {subArticles.map((subart) => (
                                   <SubArticle key={subart.ids} subArticle={subart} onChange={subArticleChange} deleteArticle={deleteSubArticle} deleteImage={handleDeleteImage} />)
                                )}                                
                                <div className='create-category-form-buttons'>
                                    {!isEdit && <Button variant="contained" color="primary" disabled={!isFormValid} type="submit">
                                        Crear
                                    </Button>}
                                    {isEdit && <Button variant="contained" color="primary" disabled={!isFormValid} type="submit">
                                        Editar
                                    </Button>}
                                    <Button variant="contained" color="secondary" onClick={() => addSubArticle()}>
                                        Añadir sub Artículo
                                    </Button>                                    
                                    <Button variant="contained" onClick={() => cancel()}>
                                        Cancelar
                                    </Button>
                                </div>
                            </form>

                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
