import React from 'react'
import MaterialTable from 'material-table';
import { useSelector } from 'react-redux';

export const OrderAdminScreen1 = (props) => {
    let { orders } = useSelector( state => state.order);
    orders = orders.map(or => ({
        ...or,
        date: new Date(or.date).toLocaleString(),
        total: or.total + ' €'
    }));

    const changePage = (event) => {
        props.changePage(event);
    }

    return (
        <>
            {(orders.length > 0) &&
                <MaterialTable
                    title="Listado de Pedidos"
                    data={orders}
                    onChangePage={changePage}
                    options={
                        {
                            pageSize: 10,
                            initialPage: props.initialPage
                        }
                    }
                    columns={[
                        { title: 'Estado', field: 'status'},
                        { title: 'Id', field: 'id'},
                        { title: 'Nombre', field: 'name' },
                        { title: 'Dirección', field: 'adress' },
                        { title: 'Teléfono', field: 'phone' },
                        { title: 'Fecha', field: 'date' },
                        { title: 'Total', field: 'total' },
                        { title: 'Observaciones', field: 'observations' }
                        ]}
                    parentChildData={(row, rows) => rows.find(a => a.id === row.superCategory?._id)}
                    actions={[
                        {
                            icon: 'visibility',
                            tooltip: 'Ver Pedido',
                            onClick: (event, rowData) => props.viewOrder(rowData)
                        },
                        {
                            icon: 'delete',
                            tooltip: 'Borrar Pedido',
                            onClick: (event, rowData) => props.deleteOrder({name: rowData.name, id:rowData.id})
                        },
                        (rowData) => {
                            return { 
                                icon: 'done_outline',
                                tooltip: 'Marcar Como Enviado',
                                hidden: rowData.status !== 'PAGADO',
                                onClick: (event, rowData) => props.sendOrder(rowData)
                            }
                         }
                    ]}
                />
            }
        </>
    )
}
