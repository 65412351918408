import React, { useEffect, useState } from 'react';
import logo1 from '../../assets/logo1.png';
import logo_large from '../../assets/logo_large.png';
import SearchBar from "material-ui-search-bar";
import { Link, useHistory, useLocation } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { CategoriesTop } from './CategoriesTop';
import { useDispatch, useSelector } from 'react-redux';
import { navigationDeleteSubText, navigationSetCart, navigationSetLogin, navigationSetRegister, navigationSetSpace } from '../../actions/navigation';
import { startLogout } from '../../actions/auth';
import { navigationDeleteText} from '../../actions/navigation';
import { setCarProducts } from '../../actions/ui';
export const MainHeader = () => {

    const [search, setSearch] = useState('');
    const history = useHistory();
    const [scroll, setScroll] = useState(false);
    const { checking, uid } = useSelector( state => state.auth);
    const { cartProducts } = useSelector( state => state.ui);

    const dispatch = useDispatch();

    useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 200);
      });
    }, []);

    const handleChange = (e) => {
        setSearch(e);
    }

    const handleSearch = (e) => {
        if(e.trim() !== '') {
            dispatch(navigationDeleteText());
            dispatch(navigationDeleteSubText());
            const url = `search/${e.trim()}`;
            setSearch('');
            history.push('/');
            history.push(url);
        }
    }

    const handleShopingCart = (e) => {
        e.preventDefault();
        history.push('/cart');
    }

    const handleLogoClick= () => {
        dispatch(navigationSetCart(false));
        dispatch(navigationSetLogin(false));
        dispatch(navigationSetRegister(false));
        dispatch(navigationSetSpace(false));
    }

    const goToLogIn = (event) => {
        event.preventDefault();
        dispatch(navigationSetCart(false));
        dispatch(navigationSetRegister(false));
        dispatch(navigationSetSpace(false));
        dispatch(navigationSetLogin(true));
    }

    const goToRegister = (event) => {
        event.preventDefault();
        dispatch(navigationSetCart(false));
        dispatch(navigationSetLogin(false));
        dispatch(navigationSetSpace(false));
        dispatch(navigationSetRegister(true));
    }

    const goToAccount = (event) => {
        event.preventDefault();
        /*dispatch(navigationSetCart(false));
        dispatch(navigationSetSpace(true));*/

        history.push('/myspace');
    }

    const logOut = (event) => {
        event.preventDefault();
        dispatch(setCarProducts(0));
        dispatch(startLogout());
        history.push('/');
    }

    return (
        <div className="main-header">
            <div className="main-header-container">
                <div className="main-header-img-mobile">
                    <Link to="/" onClick={handleLogoClick}>
                        <img className="site-logo" src={logo_large} title="Tienda online de ropa sostenible y ropa ecológica" alt="Green Forest Wear" />
                    </Link>
                </div>
                <div className="main-header-search-container">
                <div className="main-header-search">
                    <SearchBar
                        placeholder="Buscar"
                        value={search}
                        onChange={handleChange}
                        onRequestSearch={handleSearch}
                    />
                </div>
                </div>
                <div className="main-header-img">
                    <Link to="/" onClick={handleLogoClick}>
                        <img className="site-logo" src={logo_large} title="Tienda online de ropa sostenible y ropa ecológica" alt="Green Forest Wear" />
                    </Link>
                </div>
                <div className="main-header-icons">
                
                    {!uid && <a target="_blank" href="#" onClick={goToLogIn}><i className="fas fa-sign-in-alt"></i>Iniciar sesión</a>}
                    {!!uid && <a target="_blank" href="#" onClick={goToAccount}><i className="fas fa-user"></i>Mi cuenta</a>}
                    <div className="separation">|</div>
                    {!uid && <a target="_blank" href="#" onClick={goToRegister}><i class="fas fa-pencil-alt"></i>Registrarse</a>}
                    {!!uid && <a target="_blank" href="#" onClick={logOut}><i class="fas fa-sign-out-alt"></i>Cerrar sesión</a>}
                    <div className="separation">|</div>
                    

                    <Badge badgeContent={cartProducts} color="primary">
                        <a target="_blank" href="#" onClick={handleShopingCart}><i className="fas fa-shopping-bag"></i>Cesta</a>
                    </Badge>

                </div>
            </div>
            <CategoriesTop />
            <hr />  
            {
                scroll && 
                <div className="main-header-scroll animate__animated animate__fadeInDown">
                    <div className="main-header-scroll-img">
                        <Link to="/" onClick={handleLogoClick}>
                            <img className="site-logo" src={logo_large} title="Tienda online de ropa sostenible y ropa ecológica" alt="Green Forest Wear" />
                        </Link>
                    </div>
                    <div className="main-header-scroll-search">
                        <SearchBar
                            value={search}
                            onChange={handleChange}
                            onRequestSearch={handleSearch}
                        />
                    </div>
                    <div className="main-header-icons main-header-scroll-icons">
                        {!uid && <a target="_blank" href="#" onClick={goToLogIn}><i className="fas fa-sign-in-alt"></i>Iniciar sesión</a>}
                        {!!uid && <a target="_blank" href="#" onClick={goToAccount}><i className="fas fa-user"></i>Mi cuenta</a>}
                        <div className="separation">|</div>
                        {!uid && <a target="_blank" href="#" onClick={goToRegister}><i class="fas fa-pencil-alt"></i>Registrarse</a>}
                        {!!uid && <a target="_blank" href="#" onClick={logOut}><i class="fas fa-sign-out-alt"></i>Cerrar sesión</a>}
                        <div className="separation">|</div>
                        <Badge badgeContent={cartProducts} color="primary">
                    <a target="_blank" href="#" onClick={handleShopingCart}><i className="fas fa-shopping-bag"></i>Cesta</a>
                        
                    </Badge>
                    </div>
                </div>
                


            }
 
        </div>
    )
}
