import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Swal from "sweetalert2";
import Button from '@material-ui/core/Button';
import { useForm } from '../../hooks/useForm';
import { useDispatch } from 'react-redux';
import { startRegister } from '../../actions/auth';
import Typography from '@material-ui/core/Typography';

export const Register = () => {
    const dispatch = useDispatch();
    const [isFormValid, setIsFormValid] = useState(false);
    const [ formLoginValues, handleRegisterInputChange] = useForm({
        lName: '',
        lEmail: '',
        lPassword: '',
        l2Password: '',
    });
    const {lEmail, lName, lPassword, l2Password} = formLoginValues;

    useEffect(() => {
        if(lEmail.trim() !== '' && lName.trim() !== '' && lPassword.trim() !== '' && l2Password.toString().trim() !== '') {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [lEmail, lName, lPassword, l2Password]);

    const handleRegister = (e) => {
        e.preventDefault();
        if(lPassword.trim().length <6) {
            Swal.fire('Error', 'La contraseña debe tener 6 caracteres como minimo', 'error');
        }
         else if(l2Password !== lPassword) {
            Swal.fire('Error', 'Las contraseñas no coinciden', 'error');
        } else if(!validateEmail(lEmail)) {
            Swal.fire('Error', 'Email incorrecto', 'error');
        }else {
            dispatch(startRegister(lEmail, lName, lPassword));
        }
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <div>
            <div className="content-title">
                <Typography variant="h4" gutterBottom>
                    Registro
                </Typography>
            </div>
            <div className='admin-content-wrp'>
                <form className='admin-form' noValidate autoComplete="off" onSubmit={handleRegister}>
                    <TextField 
                        id="standard-basic"
                        label="Nombre"
                        name="lName"
                        value={lName}
                        onChange={handleRegisterInputChange}
                    />
                    <TextField 
                        id="standard-basic"
                        label="Email"
                        name="lEmail"
                        value={lEmail}
                        onChange={handleRegisterInputChange}
                    />
                    <TextField
                        id="standard-password-input"
                        label="Contraseña (Debe tener al menos 6 caracteres)"
                        type="password"
                        name="lPassword"
                        value={lPassword}
                        onChange={handleRegisterInputChange}
                        autoComplete="current-password"
                    />

                    <TextField
                        id="standard-password-input"
                        label="Repite la contraseña"
                        type="password"
                        name="l2Password"
                        value={l2Password}
                        onChange={handleRegisterInputChange}
                        autoComplete="current-password"
                    />

                    <div className='admin-form-button'>
                        <Button variant="contained" color="primary" type="submit" disabled={!isFormValid}>
                            Iniciar sesión
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}
