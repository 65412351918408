import React from 'react'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';

export const Article = ({article}) => {
    const history = useHistory();
    const baseUrl = process.env.REACT_APP_API_URL;

    const viewCardDetail = () => {
        const url = history.location.pathname + `/article/${article._id}`;
        history.push(url);
    }

    return (
        <Card className="article-card" onClick={viewCardDetail}>
        <CardActionArea>
          <CardMedia
            className="article-card-media"
            image={baseUrl + "/images/articles/" +article.image}
            title={article.name}
          />
          <CardContent className="article-description">
            <Typography gutterBottom variant="h5" component="h2">
                {article.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
                {article.description}
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="h5" component="h2">
                {article.price} €
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    )
}
