import { types } from './../types/types';

export const navigationSetText = (event) => ({
    type: types.navigationSetText,
    payload: event
});

export const navigationDeleteText = () => ({
    type: types.navigationDeleteText
});

export const navigationSetSubText = (event) => ({
    type: types.navigationSetSubText,
    payload: event
});

export const navigationDeleteSubText = () => ({
    type: types.navigationDeleteSubText
});

export const navigationSetCategorie = (event) => ({
    type: types.navigationSetCategorie,
    payload: event
});

export const navigationSetSubCategorie = (event) => ({
    type: types.navigationSetSubCategorie,
    payload: event
});

export const navigationSetCart = (event) => ({
    type: types.navigationSetCart,
    payload: event
});


export const navigationSetLogin = (event) => ({
    type: types.navigationSetLogin,
    payload: event
});

export const navigationSetRegister = (event) => ({
    type: types.navigationSetRegister,
    payload: event
});

export const navigationSetSpace = (event) => ({
    type: types.navigationSetSpace,
    payload: event
});

