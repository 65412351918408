import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector } from 'react-redux';
import { ImageCarrousel } from './ImageCarrousel';


export const SubArticle = ({subArticle ,onChange, deleteArticle, deleteImage}) => {
    const { colors } = useSelector( state => state.color);
    const { sizes } = useSelector( state => state.size);


    const handleInputChange = (event) => {
        subArticle[event.target.name]=event.target.value;
        onChange(subArticle);
    }

    const handleChecked = (event) => {
        subArticle[event.target.name]=!subArticle[event.target.name];
        onChange(subArticle);
    }

    const handleImageChange = (event) => {
        subArticle.imgs = event.target.files;
        onChange(subArticle);
    }

    return (
        <>
            <div className='line'></div>
            <div className="create-subArticle">
                <div className='subArticle-arrow'>
                    <span className="material-icons">
                        subdirectory_arrow_right
                    </span>
                </div>
                <div className='create-category-subform'>
                    <FormControl  aria-label="position" row>
                        <InputLabel id="demo-simple-select-label">Medida</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={subArticle.size}
                            name="size"
                            onChange={handleInputChange}
                        >
                            <MenuItem value="">
                                <em>----</em>
                            </MenuItem>
                            {sizes.map(size => <MenuItem key={size.id} value={size.id}>{size.name}</MenuItem>)}
                        </Select>
                    </FormControl >
                    <FormControl  aria-label="position" row>
                        <InputLabel id="demo-simple-select-label">Color</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={subArticle.color}
                            name="color"
                            onChange={handleInputChange}
                        >
                            <MenuItem value="">
                                <em>----</em>
                            </MenuItem>
                            {colors.map(color => <MenuItem key={color.id} value={color.id}>{color.name}</MenuItem>)}                    
                        </Select>
                    </FormControl >                                                                
                    <TextField id="standard-basic" label="*Precio" name="price" value={subArticle.price} onChange={handleInputChange}/>
                    <div className="form-checks">
                        <FormControlLabel
                            control={<Checkbox checked={subArticle.stock} onChange={handleChecked} name="stock"/>}
                            label="Stock"
                        />     
                        <FormControlLabel
                            control={<Checkbox checked={subArticle.big} onChange={handleChecked} name="big" />}
                            label="Artículo Grande"
                        />
                    </div>
                    <ImageCarrousel images={subArticle.images} deleteImage={deleteImage}/>
                    <input className="create-cagegory-image" type="file" id="file" multiple name="file" onChange={handleImageChange}/>
                </div>
                <div className="delete-subArticle">
                    <span className="material-icons" color="secondary" onClick={() => deleteArticle(subArticle)}>
                        delete
                    </span>
                </div>
                
            </div>
        </>
    )
}
