import { types } from './../types/types';

export const doneTrue = () => ({
    type: types.uiDoneTrue
});

export const doneFalse = () => ({
    type: types.uiDoneFalse
});

export const setLoading = (event) => ({
    type: types.uiSetLoading,
    payload: event
});

export const setCarProducts = (event) => ({
    type: types.uiSetCartProducts,
    payload: event
});
