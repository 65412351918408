import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { articleStartDelete } from '../../../actions/articles';
import { ArticleAdminScreen1 } from './ArticleAdminScreen1';
import { CreateArticle } from './CreateArticle';

export const ArticleAdminScreen = () => {
    const [showCreate, setShowCreate] = useState(false);
    const [articleSelected, setArticleSelected] = useState();
    const dispatch = useDispatch();
  
    const editArticle = (article) => {
        setArticleSelected(article);
        setShowCreate(true);
    }
  
    const deleteArticle = ({name, id}) => {
      Swal.fire({
        title: `Borrar Artículo ${name}`,
        text: "¿Estas seguro de borrar este artículo?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3d4c6f',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(articleStartDelete(id));
        }
      })
    }
  
    const openCreateModal = () => {
        setArticleSelected(null);
        setShowCreate(true);
    }
  
    const cancelCreateArticle = () => {
      setShowCreate(false);
    }
  
    return (
      <div>
        {!showCreate && 
          <>
            <ArticleAdminScreen1 editArticle={editArticle} deleteArticle={deleteArticle}/>
            <div className="add-button-div">
              <IconButton color="secondary" aria-label="add an alarm" onClick={openCreateModal}>
                <AddCircleIcon className="add-button"/>
              </IconButton>
            </div>
          </>
        }
        {showCreate && <CreateArticle article={articleSelected} cancel={cancelCreateArticle}/>}
      </div>
    );
}
