import React from 'react'
import { OrderAdminScreen1 } from './OrderAdminScreen1'
import Swal from "sweetalert2";
import { useState } from 'react';
import { CreateOrder } from './CreateOrder';
import { orderStartDelete, orderStartSend } from './../../../actions/orders';
import { useDispatch } from 'react-redux';


export const OrderAdminScreen = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [orderSelected, setOrderSelected] = useState();
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();

  const deleteOrder = ({name, id}) => {
      Swal.fire({
        title: `Borrar Pedido ${name}`,
        text: "¿Estas seguro de borrar este pedido?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3d4c6f',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(orderStartDelete(id));
        }
      })
    }

    const viewOrder = (order) => {
      setOrderSelected(order);
      setShowDetail(true);
    }

    const sendOrder = (order) => {
      Swal.fire({
        title: `Enviar pedido`,
        text: "¿Estas seguro de enviar este pedido?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3d4c6f',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(orderStartSend(order.id));
        }
      })
    }

    const goBack = () => {
      setShowDetail(false);
    }

    const setTablePage = (pg) => {
      console.log(pg);
      setPage(pg);
    }

    return (
        <div>
          {!showDetail && 
            <OrderAdminScreen1 initialPage={page} changePage={setTablePage} deleteOrder={deleteOrder} viewOrder={viewOrder} sendOrder={sendOrder}/>
          }
          {showDetail && <CreateOrder order={orderSelected} goBack={goBack}/>}
      </div>
    )
}
