import { fetchConToken } from "../helpers/fetch";
import Swal from "sweetalert2";
import { types } from './../types/types';
import { setLoading } from '../actions/ui';
import { doneTrue } from "./ui";

export const getClientOrders = () => {
    return async(dispatch) => {
        dispatch(setLoading(true));
        const resp = await fetchConToken('client/orders', 'GET');
        const body = await resp.json();
        dispatch(setLoading(false));
        if(body.ok) {
            dispatch(setClientOrders(
                body.pedidos
            ));
        } else {
            Swal.fire('Error', body.msg, 'error');
        }
    }
}

const setClientOrders = (data) => ({
    type: types.clientOrdersSet,
    payload: data
});

export const getClientOrderById = (id) => {
    return async(dispatch) => {
        const resp = await fetchConToken(`orders/${id}`, 'GET');
        const body = await resp.json();
        if(body.ok) {
            dispatch(setClientOrderDetail(
                body.pedido
            ));
        } else {
            Swal.fire('Error', body.msg, 'error');
        }
    }
}

const setClientOrderDetail = (data) => ({
    type: types.clientOrderSetDetail,
    payload: data
});

export const getAllOrders = () => {
    return async(dispatch) => {
        const resp = await fetchConToken('orders', 'GET');
        const body = await resp.json();
        if(body.ok) {
            dispatch(setAllOrders(
                body.pedidos
            ));
        } else {
            Swal.fire('Error', body.msg, 'error');
        }
    }
};

const setAllOrders = (data) => ({
    type: types.allOrderSet,
    payload: data
});


export const orderStartDelete = (id) => {
    return async(dispatch) => {
        dispatch(setLoading(true));
        const resp = await fetchConToken(`orders/${id}`, {}, 'DELETE');
        const body = await resp.json();
        dispatch(setLoading(false));
        if(body.ok) {
            dispatch(orderDeleted(id));
            dispatch(doneTrue());
        } else {
            Swal.fire('Error', body.msg, 'error');
        }
    }
}

const orderDeleted = (id) => ({
    type: types.orderDeleted,
    payload: id
});


export const orderStartSend = (id) => {
    return async(dispatch) => {
        dispatch(setLoading(true));
        const resp = await fetchConToken(`orders/${id}`, {status: 'ENVIADO'}, 'PUT');
        const body = await resp.json();
        dispatch(setLoading(false));
        if(body.ok) {
            dispatch(orderUpdated({id, status: 'ENVIADO'}));
            dispatch(doneTrue());
        } else {
            Swal.fire('Error', body.msg, 'error');
        }
    }
}


const orderUpdated = (status) => ({
    type: types.orderUpdated,
    payload: status
});