import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
      height: 'fit-content'
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
      }
  }));


export const ImageArticleCarrousel = ({images}) => {

    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_API_URL;
    const [imageSelected, setImageSelected] = useState();
    const [imagesToShow, setImagesToShow] = useState([]);

    useEffect(() => {
        if(images) {
            setImageSelected(images[0]);
            setImagesToShow(images.slice(1));
        }
    }, [images])

    const imageSelectedChange = (tile) => {
        setImageSelected(tile);
        setImagesToShow(images.filter(img => img !== tile));
    }

    return (
        <>{!!images &&
            <div>
                <div className={'article-imageSelected'}>
                    <img className={'article-imageSelected-img'} src={baseUrl + "/images/articles/" + imageSelected} alt={imageSelected} />
                </div>
                <div className={classes.root}>
                    <GridList className={'article-gridList'} cols={3}>
                        {imagesToShow.map((tile) => (
                            <GridListTile key={tile} onClick={() => imageSelectedChange(tile)}>
                                <img src={baseUrl + "/images/articles/" + tile} alt={tile} />
                            </GridListTile>
                        ))}
                    </GridList>
                </div>
            </div>
            }
        </>
    )
}
