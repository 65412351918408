import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: theme.palette.primary,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
  }));

export const ImageCarrousel = ({images, deleteImage}) => {
    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_API_URL;

    let cols = 0;

    if(images && images.length) {
      cols = images.length > 3 ? 3 : images.length;
    }

    return (
          <>{!!images &&
          <div className={classes.root}>
            <GridList className={classes.gridList} cols={cols}>
                {images.map((tile) => (
                    <GridListTile key={tile}>
                        <img src={baseUrl + "/images/articles/" + tile} alt={tile} />
                        <GridListTileBar
                            classes={{
                            root: classes.titleBar,
                            title: classes.title,
                            }}
                            actionIcon={
                                <IconButton aria-label={`star ${tile}`} onClick={()=> deleteImage(tile)}>
                                    <StarBorderIcon className={classes.title} />
                                </IconButton>
                            }
                        />
                    </GridListTile>
                ))}
            </GridList>
        </div>}
        </>
    )
}
