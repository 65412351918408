import React, { useEffect } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LoginScreen } from '../components/auth/LoginScreen';
import { Categorie } from '../components/shop/Categorie';
import { ShopScreen } from '../components/shop/ShopScreen';
import { HeadScreen } from '../components/head/HeadScreen';
import { SubCategorie } from '../components/shop/SubCategorie';
import { ArticleDetail } from '../components/shop/ArticleDetail';
import { CartResponse } from '../components/shop/CartResponse';
import { FooterScreen } from '../components/footer/FooterScreen';
import { AdminScreen } from '../components/auth/AdminScreen';
import { useDispatch, useSelector } from 'react-redux';
import { startChecking } from '../actions/auth';
import { getCategories } from '../actions/categories';
import { Cart } from '../components/shop/Cart';
import { Login } from '../components/shop/Login';
import { Register } from '../components/shop/Register';
import { Space } from '../components/shop/Space';
import { ArticlesSearch } from '../components/shop/ArticlesSearch';
import { setCarProducts } from '../actions/ui';

export const AppRouter = () => {

    const { showAdmin, checking } = useSelector(state => state.auth);
    const { viewCart, viewLogin, viewRegister, viewSpace } = useSelector(state => state.navigation);
    const { showLoading } = useSelector(state => state.ui);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startChecking());
        dispatch(getCategories());
        dispatch(setCarProducts(JSON.parse(localStorage.getItem('products'))?.length));
    }, [dispatch])



    if (checking) {
        return (<h5>Espere...</h5>);
    }

    return (
        <Router>
            <div className="main-content">

                {!showAdmin && <HeadScreen />}
                {showLoading && <div className='loading'>
                    <CircularProgress size={150} />
                </div>}
                {viewLogin && <Login />}
                {viewRegister && <Register />}
                {!viewCart && !viewLogin && !viewRegister && !viewSpace &&
                    <Switch>
                        <Route exact path="/login" component={LoginScreen} />
                        <Route exact path="/admin" component={AdminScreen} />
                        <Route exact path="/cart" component={() => <Cart />} />
                        <Route exact path="/myspace" component={Space} />
                        <Route exact path="/accept" component={CartResponse} />
                        <Route exact path="/cancel" component={CartResponse} />
                        <Route exact path="/" component={ShopScreen} />
                        <Route exact path="/search/:searchId" component={ArticlesSearch} />
                        <Route exact path="/search/:searchId/article/:id" render={(props) => <ArticleDetail {...props} />} />
                        <Route exact path="/categorie/:categorieId" component={Categorie} />
                        <Route exact path="/categorie/:categorieId/article/:id" render={(props) => <ArticleDetail {...props} />} />
                        <Route exact path="/categorie/:categorieId/sub/:subCategorieId" component={SubCategorie} />
                        <Route exact path="/categorie/:categorieId/sub/:subCategorieId/article/:id" render={(props) => <ArticleDetail {...props} />} />
                        <Route exact path="/article/:id" render={(props) => <ArticleDetail {...props} />} />
                        <Redirect to="/" />
                    </Switch>
                }
                {!showAdmin && <FooterScreen />}
            </div>
        </Router>
    )
}
