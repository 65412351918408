import React from 'react'
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { navigationDeleteSubText, navigationSetCategorie, navigationSetSubCategorie, navigationSetSubText, navigationSetText } from '../../actions/navigation';

export const Categories = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { categorieId } = useParams();
    const baseUrl = process.env.REACT_APP_API_URL;

    const { categories } = useSelector(state => state.category);
    let categoriesToShow = null;

    if (location.pathname === '/') {
        categoriesToShow = categories.filter(c => !c.superCategory);
        categoriesToShow.sort((a, b) => (a.order > b.order) ? 1 : -1);
    } else {
        categoriesToShow = categories.filter(c => c.superCategory?.route === props.categorieId);
    }

    const getGridListCols = (categorie) => {
        if (window.innerWidth > 1000) {
            return categorie.cols ? categorie.cols : 1;
        }

        return 1;
    }

    const getGridCols = () => {
        if (window.innerWidth > 1000) {
            return 3;
        }

        return 1;
    }

    const getCellHeight = () => {
        if (window.innerWidth > 1000) {
            return 300;
        }

        return 200;
    }

    const goToCategorie = (categorie) => {
        window.scrollTo(0, 0);
        if (location.pathname.includes('categorie')) {
            dispatch(navigationSetSubText(categorie.name));
            dispatch(navigationSetSubCategorie(categorie.route));
            dispatch(navigationSetCategorie(categorieId));
            history.push(`/categorie/${categorieId}/sub/${categorie.route}`);
        } else {
            dispatch(navigationSetText(categorie.name));
            dispatch(navigationDeleteSubText());
            history.push(`/categorie/${categorie.route}`);
        }
    }


    return (
        <div className="categories-root">
            <GridList cellHeight={getCellHeight()} className="categories-gridList" cols={getGridCols()} spacing={6}>
                {categoriesToShow.map((categorie) => (
                    <GridListTile className="categorie-grid" key={categorie.name} cols={getGridListCols(categorie)} onClick={() => goToCategorie(categorie)}>
                        <img src={`${baseUrl}/images/categories/${categorie.img}`} alt={categorie.name} />
                        <GridListTileBar
                            title={categorie.name}
                            subtitle={<span>{categorie.description}</span>}
                        />
                    </GridListTile>
                ))}
            </GridList>
        </div>
    )
}
