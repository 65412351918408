export const types = {
    navigationSetText: '[navigation] Set text',
    navigationDeleteText: '[navigation] Delete text',
    navigationSetSubText: '[navigation] Set subtext',
    navigationDeleteSubText: '[navigation] Delete subtext',
    navigationSetCategorie: '[navigation] Set Categorie',
    navigationSetSubCategorie: '[navigation] Set sub Categorie',
    navigationSetCart: '[navigation] Set Cart',
    navigationSetLogin: '[navigation] Set Login',
    navigationSetRegister: '[navigation] Set register',
    navigationSetSpace: '[navigation] Set space',
    authSetAdmin: '[auth] Set Admin',
    authRemoveAdmin: '[auth] Remove Admin',
    authCheckingFinish: '[auth] Finish checking login state',
    authStartLogin: '[auth] Start login',
    authLogin: '[auth] Login',
    authStartRegister: '[auth] Start register',
    authStartTokenRenew: '[auth] Start token renew',
    authLogout: '[auth] Logout',
    uiDoneTrue: '[ui] Done true',
    uiDoneFalse: '[ui] Done false',
    uiSetLoading: '[ui] Set Loading',
    uiSetCartProducts: '[ui] set car products',
    categoriesSet: '[category] Set categories',
    categoryFinishNew: '[category] Finish new',
    categoryDeleted: '[category] Category deleted',
    categoryUpdated: '[category] Finish edit',
    sizesSet: '[size] Set sizes',
    sizeFinishNew: '[size] Finish new',
    sizeDeleted: '[size] size deleted',
    sizeUpdated: '[size] Finish edit',
    colorsSet: '[color] Set colors',
    colorFinishNew: '[color] Finish new',
    colorDeleted: '[color] color deleted',
    colorUpdated: '[color] Finish edit',
    articlesSet: '[article] Article set',
    articleFinishNew: '[article] Finish new',
    articleDeleted: '[article] Article deleted',
    articleUpdated: '[article] Finish edit',
    articleSelected: '[article] Article selected',
    articlesByCategorySet: '[article] articlesByCategorySet',
    subArticleDeleted: '[article] sub Article deleted',
    imageDeleted: '[imageDeleted] image delted',
    checkOutPaymentStart: '[checkOut] checkOut Payment Start',
    clientOrdersSet: '[client] set client orders',
    clientOrderSetDetail: '[client] set client order detail',
    allOrderSet: '[orders] set all orders',
    orderDeleted: '[orders] order deleted',
    orderUpdated: '[orders] order updated'
};