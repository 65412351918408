import React from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm } from '../../hooks/useForm';
import { useDispatch } from 'react-redux';
import { startLogin } from '../../actions/auth';

export const AdminLoginScreen = (props) => {

    const dispatch = useDispatch();

    const [ formLoginValues, handleLoginInputChange] = useForm({
        lEmail: '',
        lPassword: ''
    });
    const {lEmail, lPassword} = formLoginValues;

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(startLogin(lEmail, lPassword));
    }

    const wrp = !!props.wrap;

    return (
        <div className={`${wrp ? "admin-content-wrp" : "admin-content"}`}>
        <form className='admin-form' noValidate autoComplete="off" onSubmit={handleLogin}>
            <TextField 
                id="standard-basic"
                label="Usuario"
                name="lEmail"
                value={lEmail}
                onChange={handleLoginInputChange}
            />
            <TextField
                id="standard-password-input"
                label="Password"
                type="password"
                name="lPassword"
                value={lPassword}
                onChange={handleLoginInputChange}
                autoComplete="current-password"
            />

            <div className='admin-form-button'>
                <Button variant="contained" color="primary" type="submit">
                    Iniciar sesión
                </Button>
            </div>
        </form>
    </div>
    )
}
