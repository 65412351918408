import { fetchConToken } from "../helpers/fetch";
import { setCarProducts } from "../actions/ui";
import Swal from "sweetalert2";
import { setLoading } from '../actions/ui';

export const checkOutStartCheck = ({ form }) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const resp = await fetchConToken('orders', form, 'POST');
            const body = await resp.json();

            if (body.ok) {
                window.location.href = body.url;
            } else {
                dispatch(setLoading(false));
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            dispatch(setLoading(false));
            Swal.fire('Error', error, 'error');
        }
    }
}

export const checkOutValidate = (form) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const resp = await fetchConToken(`orders/check/${form.id}`, form, 'POST');
            const body = await resp.json();
            dispatch(setLoading(false));
            if (body.ok) {
                dispatch(setCarProducts(0));
                localStorage.removeItem('products');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error, 'error');
        }
    }
}
