import React from 'react'
import MaterialTable from 'material-table';
import { useSelector } from 'react-redux';

export const CategoryAdminScreen1 = (props) => {
    const { categories } = useSelector( state => state.category);
    return (
        <>
            {(categories.length > 0) &&
                <MaterialTable
                    title="Listado de Categorías y subcategorías"
                    data={categories}
                    options={{
                        pageSize: 10}}
                    columns={[
                    { title: 'Nombre', field: 'name' },
                    { title: 'Descripción', field: 'description' },
                    { title: 'Ruta', field: 'route' },
                    { title: 'Orden', field: 'order' },
                    { title: 'Columnas', field: 'cols', removable: false },
                    ]}
                    parentChildData={(row, rows) => rows.find(a => a.id === row.superCategory?._id)}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Editar Categoría',
                            onClick: (event, rowData) => props.editCategory(rowData)
                        },
                        {
                            icon: 'delete',
                            tooltip: 'Borrar Categoría',
                            onClick: (event, rowData) => props.deleteCategory({name: rowData.name, id:rowData.id})
                        }
                    ]}
                />
            }
        </>
    )
}
