import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { navigationDeleteSubText, navigationSetCart, navigationSetText, navigationSetLogin, navigationSetRegister, navigationSetSpace, navigationSetSubText,
  navigationSetSubCategorie, navigationSetCategorie} from '../../actions/navigation';

export const CategoriesTop = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const baseUrl = process.env.REACT_APP_API_URL;

    let { categories } = useSelector( state => state.category);
    const handleCatClick = ({route, name}) => {
        dispatch(navigationSetCart(false));
        dispatch(navigationSetLogin(false));
        dispatch(navigationSetRegister(false));
        dispatch(navigationSetSpace(false));
        dispatch(navigationSetText(name));
        dispatch(navigationDeleteSubText());
        dispatch(navigationSetCategorie(route));
        history.push(`/categorie/${route}`);
    }
    const dynamicSort = (property) => {
      var sortOrder = 1;
      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }
      return function (a,b) {
          /* next line works with strings and numbers, 
           * and you may want to customize it to your needs
           */
          var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
          return result * sortOrder;
      }
  }
  
    const categorieData = categories.filter(cat => !cat.superCategory).map(cat => {
      let subCat = categories.filter(subcat => subcat.superCategory?._id === cat.id);
      return {
        ...cat,
        subCat
      }
    }).sort(dynamicSort('order'));



    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const goToCategorie = ({subcat, categorie}) => {
      dispatch(navigationSetSubText(''));
      dispatch(navigationSetSubCategorie(''));
      dispatch(navigationSetCategorie(''));
      dispatch(navigationSetText(''));
      history.push(`/categorie/${categorie.id}/sub/${subcat.route}`);
    }

    return (
        <div className="main-header-container">
            <ul>
                {categorieData && 
                    categorieData.map((cat)=> (
                      <div className="dropdown" key={cat.name}>
                        <li key={cat.name} className="dropbtn" onMouseOver={handleMouseEnter}><a onClick={() => handleCatClick(cat)}>{cat.name}</a></li>
                        <div className="dropdown-content">
                          <img className="dropdown-content-image" src={`${baseUrl}/images/categories/${cat.img}`} alt={cat.name} />

                          {cat.subCat && cat.subCat.map((subcat) => (
                            <a key={subcat.name} className="route-title-first" onClick={() => goToCategorie({subcat: subcat, categorie: cat})}>{subcat.name}</a>
                          ))}
                        </div>        
                      </div>
                    ))
                }
            </ul>
        </div>
    )
}
