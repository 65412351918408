


import { types } from "../types/types";

const initialState = {
    orders: []
}

export const ordersReducer = ( state = initialState, action ) => {
    switch(action.type) {
       case types.allOrderSet:
           return {
               ...state,
               orders: action.payload
           }
        case types.orderDeleted:
            return {
                ...state,
                orders: state.orders.filter(c => (
                    c.id !== action.payload) 
                )
            }
        case types.orderUpdated: 
            return {
                ...state,
                orders: state.orders.map(e => (
                    e.id === action.payload.id) ?  {...e, status: action.payload.status} : e
               )
            }
        default:
            return state;
    }
}