import React, { useEffect, useState } from 'react'
import { articlesData } from './articlesData';
import { getImage } from './../../helpers/utils';
import Swal from "sweetalert2";
import Typography from '@material-ui/core/Typography';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { ImageArticleCarrousel } from './ImageArticleCarrousel';
import { getArticleById } from '../../actions/articles';
import { useHistory, useLocation } from 'react-router-dom';
import { setCarProducts } from '../../actions/ui';

export const ArticleDetail = (props) => {
    const [isFormValid, setIsFormValid] = useState(true);
    const [isAmountValid, setIsAmountValid] = useState(true);
    const {articleSelected} = useSelector(state => state.article);
    const [articleDefault, setArticleDefault] = useState({});
    const [cantidad, setCantidad] = useState(1);
    const [colors, setColors] = useState([]);
    const [sizes, setSizes] = useState([]);
    const location = useLocation();
    const history = useHistory();
    const [showConfirm, setShowConfirm] = useState(false);
    const {textRoute, subTextRoute, categorieRoute, subCategorieRoute} = useSelector(state => state.navigation);
    const dispatch = useDispatch();

    useEffect(() => {
        const id = location.pathname.substr(location.pathname.lastIndexOf("/")+1)
        dispatch(getArticleById(id));
    }, [dispatch, location.pathname]);
    

    const sizeHandleChange = (event) => {
        const newArDefault = articleSelected.articulosDetalle.find(ar => ar.size._id === event.target.value &&
            ( articleDefault.color ? ar.color._id === articleDefault.color._id : true));
        if(newArDefault) {
            setArticleDefault(newArDefault);
            setIsFormValid(true);
        } else {
            let color = articleDefault.color;
            //setArticleDefault(articleSelected.articulosDetalle.find(ar => ar.size._id === event.target.value));
            setArticleDefault({
                imgs: ['unk'],
                size: {'_id': event.target.value},
                color
            });
            setIsFormValid(false);
        }
        
    }

    const colorHandleChange = (event) => {
        const newArDefault = articleSelected.articulosDetalle.find(ar => ar.color._id === event.target.value && 
            (articleDefault.size ? ar.size._id === articleDefault.size._id : true)
            );
        if(newArDefault) {
            setArticleDefault(newArDefault);
            setIsFormValid(true);
        } else {
            //setArticleDefault(articleSelected.articulosDetalle.find(ar => ar.color._id === event.target.value));
            const size = articleDefault.size;
            setArticleDefault({
                imgs: ['unk'],
                color: {'_id': event.target.value},
                size
            });
            setIsFormValid(false);
        }
    }

    const handleChange = (event) => {
        if(event.target.value >= 1) {
            setCantidad(parseInt(event.target.value));
            setIsAmountValid(true);
        } else {
            setCantidad(parseInt(event.target.value));
            setIsAmountValid(false);
        }
    }

    const submitForm = () => {
        let products = [];
        if(localStorage.getItem('products')){
            products = JSON.parse(localStorage.getItem('products'));
        }

        let prdc = products.find(pr => pr.productId === articleDefault.id);
        if(prdc) {
            Swal.fire({
                title: 'Este producto ya está en su cesta de la compra',
                text: "¿Desea añadirlo de todos modos?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3d4c6f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, añadir',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.isConfirmed) {
                    products = products.map(pr => {
                        if(pr.productId === articleDefault.id) {
                            return {
                                'productId': pr.productId,
                                'cantidad': pr.cantidad + cantidad,
                                'color': articleDefault.color,
                                'imgs': articleDefault.imgs,
                                'price': articleDefault.price,
                                'size': articleDefault.size,
                                'name': articleSelected.name
                            }
                        } else return pr;
                    })
                    localStorage.setItem('products', JSON.stringify(products));
                    dispatch(setCarProducts(products.length));
                    setShowConfirm(true);
                    setTimeout(() => {
                        setShowConfirm(false);
                    }, 3000);
                }
              })
        } else {
            
            products.push({
                'productId' : articleDefault.id,
                'cantidad': cantidad,
                'color': articleDefault.color,
                'imgs': articleDefault.imgs,
                'price': articleDefault.price,
                'size': articleDefault.size,
                'name': articleSelected.name
            });
            localStorage.setItem('products', JSON.stringify(products));
            dispatch(setCarProducts(products.length));
            setShowConfirm(true);
            setTimeout(() => {
                setShowConfirm(false);
            }, 3000);
        }
    }

    useEffect(() => {
        if(articleSelected){
            setArticleDefault(articleSelected.articulosDetalle.find(ar => !!ar.default));
            const seen = new Set();
            const uniqueColors = articleSelected.articulosDetalle.map(ar => ar.color).filter(el => {
                if(el) {
                    const duplicate = seen.has(el._id);
                    seen.add(el._id);
                    return !duplicate;
                } else {
                    return false;
                }
              });
            setColors(uniqueColors);
            const seen1 = new Set();
            const uniqueSizes = articleSelected.articulosDetalle.map(ar => ar.size).filter(el => {
                if(el) {
                    const duplicate = seen1.has(el._id);
                    seen1.add(el._id);
                    return !duplicate;
                } else {
                    return false;
                }
              });
            setSizes(uniqueSizes);
        }        
    }, [articleSelected]);

    const goToParent = () => {
        history.push(`/categorie/${categorieRoute}`);
    }

    const goToParent2 = () => {
        history.push(`/categorie/:categorieId/sub/${subCategorieRoute}`);
    }

    return (
        <>
        {textRoute && subTextRoute && <div className="route-title">
                <div className="flex-row">
                    <Typography gutterBottom variant="h5" component="h2" className="route-title-first" onClick={goToParent}>
                        {textRoute}
                    </Typography>
                    {!!subTextRoute && <Typography gutterBottom variant="h5" component="h2">
                        /
                    </Typography>}
                    <Typography gutterBottom variant="h5" component="h2" className="route-title-first" onClick={goToParent2}>
                        {subTextRoute}
                    </Typography>
                </div>
                <Button color="secondary" variant="contained" onClick={goToParent2}>
                            Volver
                        </Button>
            </div>}
        {textRoute && !subTextRoute && <div className="route-title">
            <div className="flex-row">
                <Typography gutterBottom variant="h5" component="h2" className="route-title-first" onClick={goToParent}>
                    {textRoute}
                </Typography>
            </div>
            <Button color="secondary" variant="contained" onClick={goToParent}>
                            Volver
                        </Button>
        </div>}
        {articleSelected &&
        <div className="article-detail">
            {articleDefault.imgs &&<div className="article-img">
                <ImageArticleCarrousel images={articleDefault.imgs} />
            </div>}
            <div className="article-data">
                <h2>{articleSelected.name}</h2>
                <h3>{articleSelected.description}</h3>
                <h2>{articleDefault.price}€</h2>
                <div className="article-detail-form">
                    {articleDefault.size && <FormControl className="article-form-control">
                        <InputLabel id="demo-simple-select-label">Medidas</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={articleDefault.size._id}
                            onChange={sizeHandleChange}
                        >
                            {sizes.map((size) => (
                                <MenuItem key={size._id} value={size._id}>{size.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>}
                    {articleDefault.color && <FormControl className="article-form-control">
                        <InputLabel id="demo-simple-select-label">Color</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={articleDefault.color._id}
                            onChange={colorHandleChange}
                        >
                            {colors.map((color) => (
                                <MenuItem key={color._id} value={color._id}>{color.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>}
                    <form noValidate autoComplete="off">
                      <TextField 
                        className="article-form-control"
                        id="standard-basic"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={cantidad}
                        label="Cantidad"
                        onChange={handleChange}/>
                    </form>
                    {!isFormValid && <p className="comb-disp">Combinacion no disponible</p>}
                    {!articleDefault.stock && isFormValid && <h3 className="comb-disp">Actualmente sin stock</h3>}
                    {articleDefault.stock && 
                        <div className="article-detail-button">
                            <Button color="primary" variant="contained" onClick={submitForm} disabled={!isFormValid || !isAmountValid}>
                                <i class="fas fa-shopping-cart"></i>   Añadir a la cesta
                            </Button>

                            {showConfirm && <div className="article-cart-confirm">
                                <i class="fas fa-check-circle"></i>Articulo añadido a la cesta
                            </div>}
                        </div>
                    }
                </div>
            </div>
        </div>}
        </>
    )
}
