import { types } from "../types/types";

const initialState = {
    categories: [],
    done: false
}

export const categoryReducer = ( state = initialState, action ) => {
    switch(action.type) {
       case types.categoriesSet:
           return {
               ...state,
               categories: action.payload
           }
        case types.categoryFinishNew:
            return {
                ...state,
                categories: [...state.categories, action.payload]
            }
        case types.categoryDeleted:
            return {
                ...state,
                categories: state.categories.filter(c => (
                        c.id !== action.payload) 
                )
            }
        case types.categoryUpdated:
            return {
                ...state,
                categories: state.categories.map(e => (
                     e.id === action.payload.id) ? action.payload : e
                )
            }
        default:
            return state;
    }
}