import React from 'react'

export const CartArticle = ({article, deleteArticle}) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const img = article.imgs.length > 0 ? article.imgs[0] : 'default';
    const description = `${article.name} ${article.color ? article.color.name : ''} ${article.size ? article.size.name : ''}`;
    return (
        <div>
            <div className="cart-article">
                <div className="cart-image">
                    <img src={baseUrl + "/images/articles/" + img} alt={img} />
                </div>
                <div className="cart-article-detail">
                    <div className="cart-article-detail2">
                        <h2>{description}</h2>
                        {!article.stock && <h2 className="comb-disp">ACTUALMENTE SIN STOCK</h2>}
                    </div>
                    <h3>Cantidad: {article.cantidad}</h3>    
                    <div className="flex-row-center">
                        <h3>Eliminar Articulo</h3>
                        <span className="material-icons route-title-first" color="secondary" onClick={() => deleteArticle(article.productId)}>
                            delete
                        </span>
                    </div>
                    <div className="cart-price-mobile flex-row-center">
                        <h2>Precio:</h2>
                        <h2>{article.price}€</h2>
                    </div>
                </div>
                <div className="cart-price-screen">
                    <h2>Precio:</h2>
                    <h2>{article.price}€</h2>
                </div>
                
                
            </div>
        <hr/>
        </div>
    )
}
