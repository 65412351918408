import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { categoryStartAddNew, categoryStartEdit } from '../../../actions/categories';


const initEvent = {
    name: '',
    description: '',
    route: '',
    cols: '',
    order: '',
    superCategory: '',
    img: ''
};

export const CreateCategory = ({category, cancel}) => {
    const [subChecked, setSubChecked] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [image, setImage] = useState('');
    const { categories } = useSelector( state => state.category);
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState(initEvent);
    const {name, description, route, cols, order, superCategory, img} = formValues;
    const baseUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(name.trim() !== '' && description.trim() !== '' && route.trim() !== '') {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [name, description, route])

    const isEdit = !!category;

    useEffect(() => {
        if(isEdit) {
            setFormValues({
                ...category,
                superCategory: category.superCategory?._id
            });
            if (category.superCategory) {
                setSubChecked(true);
            }
        } else {
            setFormValues(initEvent);
        }
    }, [isEdit, setFormValues, category]);

    const hasChilds = !!category ? (categories.filter(cat => cat.superCategory?._id === category.id)).length > 0 : false;
    const superCategories = categories.filter(cat => !cat.superCategory);
    const categoriesToShow = isEdit ? (superCategories.map(cat => ({id: cat.id, name: cat.name})).filter(cat => cat.id !== category.id)) : superCategories.map(cat => ({id: cat.id, name: cat.name}));

    const handleInputChange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.name]:target.value
        })
    }

    const handleSuperChange = () => {
        if(hasChilds) {
            Swal.fire({
                icon: 'warning',
                title: 'Categoría con subcategorías',
                text: 'Esta categoría no puede ser subcategoría por tener subcategorías asignadas, cambie primero estás para poder asignarle una supercategoría'
              })
        } else {
            setSubChecked(!subChecked);

            if(!!subChecked) {
                handleInputChange({target: {name: 'superCategory', value: ''}});
            }
        }
    }

    const handleSelectChange = (event) => {
        handleInputChange({target: {name: 'superCategory', value: event.target.value}});
    }

    const handleImageChange = (event) => {
        setImage(event.target.files[0]);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(isEdit) {
            dispatch(categoryStartEdit({form: formValues, file: image}));
        } else {
            dispatch(categoryStartAddNew({form: formValues, file: image}));
        }
        cancel();
    }
    
    return (
        <div>
            <Container maxWidth="lg">
                <h1 className='create-title'>{isEdit ? 'Editar Categoría' : 'Crear categoria'}</h1>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className='create-category-container'>
                            <form className='create-category-form' noValidate autoComplete="off" onSubmit={handleSubmit}>
                                <TextField id="standard-basic" label="*Nombre" name="name" value={name} onChange={handleInputChange}/>
                                <TextField id="standard-basic" label="*Descripción" name="description" value={description} onChange={handleInputChange}/>
                                <TextField id="standard-basic" label="*Ruta" name="route" value={route} onChange={handleInputChange}/>
                                <TextField id="standard-basic" label="Columnas" type="number" name="cols" value={cols} onChange={handleInputChange}/>
                                <TextField id="standard-basic" label="Orden" type="number" name="order" value={order} onChange={handleInputChange}/>
                                { isEdit && !image && <img className='create-cagegory-image' src={`${baseUrl}/images/categories/${img}`} alt='imagen cargada'/> }
                                <TextField
                                    name="image"
                                    type="file"
                                    onChange={handleImageChange}
                                />
                                <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    control={<Checkbox checked={subChecked} onChange={handleSuperChange} name="Subcategoria" />}
                                    label="Subcategoria"
                                />
                                </FormGroup>
                                {subChecked && <>
                                    <FormControl  aria-label="position" row>
                                        <InputLabel id="demo-simple-select-label">Categoría</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={superCategory}
                                            onChange={handleSelectChange}
                                        >
                                            <MenuItem value="">
                                                <em>----</em>
                                            </MenuItem>
                                            {categoriesToShow.map(cat => <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>)}
                                        </Select>
                                    </FormControl >
                                </>}
                                <div className='create-category-form-buttons'>
                                {!isEdit && <Button variant="contained" color="primary" disabled={!isFormValid} type="submit">
                                    Crear
                                </Button>}
                                {isEdit && <Button variant="contained" color="primary" disabled={!isFormValid} type="submit">
                                    Editar
                                </Button>}
                                <Button variant="contained" onClick={() => cancel()}>
                                    Cancelar
                                </Button>
                            </div>
                            </form>

                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
